<template>
  <b-modal
    v-model="showModal"
    size="xl"
    id="customPeerGroup"
    modal-class="custom-peer-group"
    ref="customPeerGroup"
    @show="setInitData"
    body-class="cpg-body"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <template slot="modal-header">
      <h5 class="modal-title">{{ peerGroupText }}</h5>
      <button type="button" class="close" @click.prevent="closeModal">x</button>
    </template>
    <div>
      <b-card no-body>
        <b-tabs v-model="tabIndex" card>
          <b-tab>
            <template slot="title">
              <h6 class="tab-title">Search by FI Name</h6>
            </template>
            <v-wait for="fetchingFIData">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="20"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <FISearchCustomPeerGroup
                :type="type"
                :peerGroupId="peerGroupId"
                :tabIndex="tabIndex"
                :selectedFI="selectedFI"
                @pgCreated="peerGroupCreated"
                @pgUpdated="peerGroupUpdated"
                @closeModal="closeModal"
              >
              </FISearchCustomPeerGroup>
            </v-wait>
          </b-tab>
          <b-tab @click="quickSearch()">
            <template slot="title">
              <h6 class="tab-title">Search by Quick Criteria</h6>
            </template>
            <v-wait for="fetchingQuickSearchData">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="20"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <QuickSearchCustomPeerGroup
                :type="type"
                :peerGroupId="peerGroupId"
                :tabIndex="tabIndex"
                :selectedFI="selectedFI"
                @pgCreated="peerGroupCreated"
                @pgUpdated="peerGroupUpdated"
                @closeModal="closeModal"
              >
              </QuickSearchCustomPeerGroup>
            </v-wait>
          </b-tab>
          <b-tab @click="advancedSearch()">
            <template slot="title">
              <h6 class="tab-title">Search by Advanced Criteria</h6>
            </template>
            <v-wait for="fetchingAdvancedSearchData">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="20"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <AdvancedSearchCustomPeerGroup
                :type="type"
                :tabIndex="tabIndex"
                :selectedFI="selectedFI"
                @pgCreated="peerGroupCreated"
                @pgUpdated="peerGroupUpdated"
                @closeModal="closeModal"
              ></AdvancedSearchCustomPeerGroup>
            </v-wait>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import FISearchCustomPeerGroup from './FISearchCustomPeerGroup'
import QuickSearchCustomPeerGroup from './QuickSearchCustomPeerGroup'
import AdvancedSearchCustomPeerGroup from './AdvancedSearchCustomPeerGroup'
import { mapActions } from 'vuex'

export default {
  name: 'CustomPeerGroup',
  components: {
    FISearchCustomPeerGroup,
    QuickSearchCustomPeerGroup,
    AdvancedSearchCustomPeerGroup
  },
  props: {
    showModal: {
      required: true,
      type: Boolean
    },
    type: {
      required: true,
      type: String
    },
    selectedFI: {
      required: true,
      type: Object
    },
    peerGroupId: {}
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    peerGroupText() {
      return this.type === 'edit' ? 'Edit Peer Group' : 'Create Peer Group'
    }
  },
  methods: {
    ...mapActions('CustomPeerGroup', [
      'fetchMandatoryData',
      'fetchQuickSearchInitData',
      'fetchAdvancedSearchInitData',
      'resetAllUserInputs'
    ]),
    setInitData() {
      this.$wait.start('fetchingFIData')
      this.fetchMandatoryData({
        type: this.type,
        fiType: this.selectedFI.fi_type,
        peerGroupId: this.peerGroupId
      }).then(() => {
        this.$wait.end('fetchingFIData')
      })
    },
    quickSearch() {
      this.$wait.start('fetchingQuickSearchData')
      this.fetchQuickSearchInitData(this.selectedFI.id).then(() => {
        this.$wait.end('fetchingQuickSearchData')
      })
    },
    advancedSearch() {
      this.$wait.start('fetchingAdvancedSearchData')
      this.fetchAdvancedSearchInitData(this.selectedFI.id).then(() => {
        this.$wait.end('fetchingAdvancedSearchData')
      })
    },
    peerGroupCreated() {
      this.$emit('peerGroupCreated')
      this.resetAllUserInputs()
      this.closeModal()
    },
    peerGroupUpdated() {
      this.$emit('peerGroupUpdated')
      this.resetAllUserInputs()
      this.closeModal()
    },
    closeModal() {
      this.tabIndex = 0
      this.resetAllUserInputs()
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  font-weight: 600;
}
</style>

<style lang="scss">
.custom-peer-group {
  @media (min-width: 768px) {
    .modal-xl {
      width: 75%;
      max-width: 1200px;
    }
  }

  .card {
    .card-header {
      padding-top: 1.3rem;

      .nav-tabs {
        .nav-link.active {
          border-top: solid 1px #a4b7c1;
        }
      }
    }
  }

  .cpg-body {
    max-height: 40rem;
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.cpg-multi-select {
  .multiselect__tags {
    border: solid 1px #c2cfd6;
    min-height: 35px;
    padding-top: 4px;
  }

  li.multiselect__element {
    .multiselect__option {
      padding: 7px;
      min-height: 30px;
    }
  }
}
</style>
