<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <b-list-group class="no-border" :class="{ loading: this.loading }">
        <div class="row">
          <div class="col-sm-1"></div>

          <div class="col-sm-10 px-5 pt-2">
            <div>
              Use the search tool below to select financial institutions. Search
              by Name, City, State, Federal Reserve ID, Regulatory ID or ABA
              number.
            </div>

            <div class="row pt-3">
              <b-form class="d-flex search-form" autocomplete="off">
                <div class="col-sm-6">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Search</b>
                    </label>
                    <b-form-input
                      type="text"
                      ref="searchInstitution"
                      class="form-control rounded"
                      placeholder="FI Name, Federal Reserve ID, Regulatory ID or ABA"
                      v-model="searchTextField"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-3 pl-0">
                  <b-form-group>
                    <label for="City" class="mb-1">
                      <b>City</b>
                    </label>
                    <b-form-input
                      type="text"
                      id="city"
                      class="form-control rounded"
                      placeholder="Enter City (optional)"
                      v-model="searchCityField"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-3 pl-0">
                  <b-form-group>
                    <label for="State" class="mb-1">
                      <b>State</b>
                    </label>
                    <b-form-select
                      v-model="searchState"
                      @input="searchInstitutions"
                    >
                      <template slot="first">
                        <option :value="null">State (optional)</option>
                      </template>
                      <option
                        :value="state.code"
                        v-for="(state, index) in states"
                        :key="index"
                        >{{ state.name }}</option
                      >
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-form>
            </div>
          </div>
        </div>

        <div class="row list-group-container">
          <div class="col-sm-12">
            <div class="row card-sub-header mt-0">
              <div class="col-sm-2 pr-0"></div>
              <div class="col-sm-5 card-sub-header">
                <span v-if="institutions.length">
                  Search Results:
                  <i>{{ institutions.length | numberFormat }} FIs</i>
                </span>
                <span v-else>
                  Search Results
                </span>
              </div>
              <div class="col-sm-3 text-right pr-0 align-self-center">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="addAll"
                  class="mx-3 font-weight-bold"
                  >Add All</b-button
                >
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>

          <div class="col-sm-2 align-self-center pl-5 tip-text">
            <b> <i> Click to Add to Peer Group </i> </b>
          </div>
          <div class="col-sm-8">
            <div class="full-border">
              <v-wait for="fetchingFIs">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="7"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div
                  class="filter-result"
                  v-on:scroll.passive="fetchMoreData($event)"
                >
                  <b-card class="text-center" body-class="p-0">
                    <b-row>
                      <b-col md="12">
                        <div>
                          <b-card class="text-left">
                            <b-list-group
                              v-if="emptyInstitutions"
                              class="text-center"
                            >
                              <b-list-group-item>
                                <i>No Institutions found ...</i>
                              </b-list-group-item>
                            </b-list-group>
                            <b-list-group v-else>
                              <div
                                v-for="institution in institutions"
                                :key="institution.id"
                              >
                                <b-list-group-item
                                  @click="setInstitution(institution)"
                                  :class="{
                                    selected:
                                      institution.id === selectedInstitution.id
                                  }"
                                  :disabled="loading"
                                >
                                  {{ institution.full_name }}&#44;
                                  {{ institution.city }}&#44;
                                  {{ institution.state }}
                                  {{ '[' + institution.id + ']' }}
                                  <span
                                    class="float-right fa fa-check pt-1 add-icon"
                                  ></span>
                                </b-list-group-item>
                              </div>
                            </b-list-group>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </v-wait>
            </div>
          </div>
        </div>

        <div class="row list-group-container">
          <div class="col-sm-12">
            <div class="row card-sub-header mt-0">
              <div class="col-sm-2 pr-0"></div>
              <div class="col-sm-5 card-sub-header">
                <span v-if="selectedInstitutions.length">
                  Peer Group Members:
                  <i>{{ selectedInstitutions.length | numberFormat }} FIs</i>
                </span>
                <span v-else>
                  Peer Group Members
                </span>
              </div>
              <div class="col-sm-3 text-right pr-0 align-self-center">
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="removeAll"
                  class="mx-3 font-weight-bold"
                  >Remove All</b-button
                >
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>

          <div class="col-sm-2 align-self-center pl-5 tip-text">
            <b> <i> Click to Remove from Peer Group </i> </b>
          </div>

          <div class="col-sm-8">
            <div>
              <div
                class="selected-result rounded form-control p-0"
                :class="{
                  'is-invalid': $v.selectedInstitutions.$error,
                  'is-valid': !$v.selectedInstitutions.$invalid
                }"
              >
                <b-card class="text-center" body-class="p-0">
                  <b-row>
                    <b-col md="12">
                      <div>
                        <b-card class="text-left">
                          <b-list-group>
                            <div
                              v-for="institution in selectedInstitutions"
                              :key="institution.id"
                            >
                              <b-list-group-item
                                v-on:click.once="removeInstitution(institution)"
                                :class="{
                                  selected:
                                    institution.id === selectedInstitution.id
                                }"
                                :disabled="loading"
                              >
                                {{ institution.full_name }}&#44;
                                {{ institution.city }}&#44;
                                {{ institution.state }}
                                {{ '[' + institution.id + ']' }}
                                <span
                                  class="float-right fa fa-trash pt-1 remove-icon"
                                ></span>
                              </b-list-group-item>
                            </div>
                          </b-list-group>
                        </b-card>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
              <b-form-invalid-feedback v-if="!$v.selectedInstitutions.required">
                Peer Group members can't be blank
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                class="larger-font"
                v-if="!$v.selectedInstitutions.belowMaxLimit"
              >
                <b>Peer Group Members count cannot exceed 2000.</b>
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <b-form-group>
              <label for="Search" class="mb-1">
                <b>Peer Group Name</b>
              </label>
              <b-form-input
                type="text"
                class="form-control rounded"
                placeholder="Enter a Peer Group Name (required)"
                v-model="pgName"
                :class="{
                  'is-invalid': $v.pgName.$error,
                  'is-valid': !$v.pgName.$invalid
                }"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.pgName.required"
                >Peer Group name can't be blank</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <b-form-group>
              <label for="Search" class="mb-1">
                <b>Peer Group Description</b>
              </label>
              <b-form-textarea
                id="description"
                class="form-control rounded"
                v-model="pgDescription"
                rows="3"
                max-rows="10"
              >
              </b-form-textarea>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <b-form-group>
              <label for="Search" class="mb-1">
                <b>Groups Shared</b>
              </label>
              <multiselect
                v-model="sharedGroups"
                :options="userGroups"
                :multiple="true"
                :close-on-select="false"
                label="name"
                track-by="id"
                :showLabels="false"
                placeholder
                class="simple-select w-50"
                :searchable="false"
              >
                <template slot="selection" slot-scope="{ values }"
                  >{{ values.length }}
                  {{ values.length | pluralize('group') }}
                  selected</template
                >
              </multiselect>
            </b-form-group>
          </div>
        </div>

        <div class="flex-row align-items-top">
          <div class="container">
            <b-row class="list-group-container">
              <b-col sm="2"> </b-col>
              <b-col sm="8" class="text-center">
                <b-button
                  size
                  variant="secondary"
                  class="fw-btn mx-3"
                  @click="closeModel"
                  >Close</b-button
                >
                <b-button
                  size=""
                  variant="primary"
                  class="fw-btn-ext mx-3"
                  :disabled="submittingForm"
                  @click="savePeerGroup"
                  v-if="!isEdit"
                  >Save & Create Peer Group</b-button
                >
                <b-button
                  size=""
                  variant="primary"
                  class="fw-btn-ext mx-3"
                  :disabled="submittingForm"
                  @click="updatePeerGroup"
                  v-else
                >
                  Update Peer Group
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-list-group>
    </div>
    <b-modal
      id="peerModifiedAlert"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="forceCloseModal"
      title="Alert"
    >
      Changes made to peer group are not saved. Do you still want to close?
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

export default {
  name: 'FISearchCustomPeerGroup',
  components: {
    Multiselect
  },
  props: {
    selectedFI: {
      required: true,
      type: Object
    },
    tabIndex: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      searchText: '',
      searchTextField: '',
      searchCity: null,
      searchCityField: null,
      searchState: null,
      selectedInstitution: {},
      offset: 0,
      loading: false,
      submittingForm: false
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      states: state => state.fiStatesList,
      institutions: state => state.fiInstitutionsList,
      selectedInstitutions: state => state.selectedInstitutions,
      peerGroup: state => state.peerGroup
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapGetters('CustomPeerGroup', [
      'checkPeerName',
      'checkPeerDescription',
      'checkPeerMembers'
    ]),
    ...mapFields(['sharedGroups']),
    pgName: {
      get: function() {
        return this.peerGroup.name
      },
      set: function(newVal) {
        return this.setPeerGroup({ name: newVal })
      }
    },
    pgDescription: {
      get: function() {
        return this.peerGroup.description
      },
      set: function(newVal) {
        return this.setPeerGroup({ description: newVal })
      }
    },
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    selectedInstitutionIds() {
      return this.selectedInstitutions.map(si => si.id)
    },
    isEdit() {
      return this.type === 'edit'
    }
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setFIInstitutionsList',
      'setSelectedInstitutions',
      'setPeerGroup'
    ]),
    searchInstitutions: function() {
      this.$wait.start('fetchingFIs')
      this.offset = 0
      this.selectedInstitution = {}
      return this.$http
        .get('/api/banks', {
          params: {
            fi_type: this.selectedFI.fi_type,
            search_text: this.searchText,
            city: this.searchCity,
            state: this.searchState
          },
          handleErrors: true
        })
        .then(res => {
          this.setFIInstitutionsList(res.data.financial_institutions)
        })
        .then(() => {
          this.loading = false
          this.$wait.end('fetchingFIs')
        })
    },
    search: _.debounce(function() {
      this.searchInstitutions()
    }, 500),

    setInstitution: function(institution) {
      this.selectedInstitution = institution
      if (!this.selectedInstitutionIds.includes(institution.id)) {
        let selectedIns = this.selectedInstitutions
        selectedIns.unshift(institution)
        this.setSelectedInstitutions(selectedIns)
      }
    },
    removeInstitution: function(institution) {
      let selectedIns = this.selectedInstitutions
      selectedIns.splice(selectedIns.indexOf(institution), 1)
      this.setSelectedInstitutions(selectedIns)
    },
    peerDataModified() {
      return !(
        this.checkPeerName &&
        this.checkPeerDescription &&
        this.checkPeerMembers
      )
    },
    closeModel: function() {
      if (this.peerDataModified()) {
        this.$bvModal.show('peerModifiedAlert')
      } else {
        this.clearData()
        this.$emit('closeModal')
      }
    },
    forceCloseModal() {
      this.clearData()
      this.$emit('closeModal')
    },
    clearData() {
      this.searchText = ''
      this.searchTextField = ''
      this.searchCity = null
      this.searchCityField = null
      this.searchState = null
      this.pgName = null
      this.pgDescription = null
      this.setSelectedInstitutions([])
    },
    fetchMoreData(event) {
      let el = event.target
      if (Math.round(el.scrollTop) === el.scrollHeight - el.offsetHeight) {
        this.fetchAdditionalInstitutions()
      }
    },
    addAll() {
      let institutionsToAdd = _.unionBy(
        this.institutions,
        this.selectedInstitutions,
        'id'
      )
      this.setSelectedInstitutions(institutionsToAdd)
    },
    removeAll() {
      this.setSelectedInstitutions([])
    },
    fetchAdditionalInstitutions() {
      if (this.offset < this.institutions.length) {
        this.loading = true
        this.offset = this.institutions.length
        this.$http
          .get('/api/banks', {
            params: {
              search_text: this.searchText,
              city: this.searchCity,
              state: this.searchState,
              offset: this.offset,
              fi_type: this.selectedFI.fi_type
            },
            handleErrors: true
          })
          .then(res => {
            let institutionsList = this.institutions.concat(
              res.data.financial_institutions
            )
            this.setFIInstitutionsList(institutionsList)
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    savePeerGroup() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submittingForm = true

        this.$http
          .post(
            '/api/peer_groups',
            {
              bank_ids: this.selectedInstitutionIds,
              peer_group: {
                name: this.pgName,
                description: this.pgDescription,
                group_peer_groups_attributes: this.sharedGroups.map(group => {
                  return { group_id: group.id, peer_group_access_id: 2 }
                })
              },
              bank_id: this.selectedFI.id
            },
            { handleErrors: true }
          )
          .then(
            res => {
              this.$toasted.show('Peer Group created successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.$ahoy.track('peer_group_created', {
                peer_group_id: res.data.peer_group_id
              })
              this.clearData()
              this.$emit('pgCreated')
            },
            error => {
              if (error.response) {
                this.$toasted.show(error.response.data.message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
            }
          )
          .then(() => {
            this.submittingForm = false
          })
      }
    },
    updatePeerGroup() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submittingForm = true

        this.$http
          .patch(
            `/api/peer_groups/${this.peerGroup.id}`,
            {
              bank_ids: this.selectedInstitutionIds,
              group_ids: this.sharedGroups.map(group => group.id),
              peer_group: {
                id: this.peerGroup.id,
                name: this.pgName,
                description: this.pgDescription
              },
              bank_id: this.selectedFI.id
            },
            { handleErrors: true }
          )
          .then(
            () => {
              this.$toasted.show('Peer Group updated successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.clearData()
              this.$emit('pgUpdated')
            },
            error => {
              if (error.response) {
                this.$toasted.show(error.response.data.message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
            }
          )
          .then(() => {
            this.submittingForm = false
          })
      }
    }
  },
  watch: {
    searchTextField(newVal) {
      if (newVal !== this.searchText) {
        this.searchText = newVal
        this.search()
      }
    },
    searchCityField(newVal) {
      if (newVal !== this.searchCity) {
        this.searchCity = newVal
        this.search()
      }
    }
  },
  validations: {
    pgName: {
      required
    },
    selectedInstitutions: {
      required,
      belowMaxLimit(value) {
        return value && value.length <= 2000
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-institute-card {
  padding: 0.7em;
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}

.tip-text {
  color: #808080;
  font-weight: 350;
}

.search-form {
  width: 100%;
}
.larger-font {
  font-size: 100%;
}
</style>
