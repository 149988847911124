<template>
  <b-card-body class="py-3 px-5">
    <b-form>
      <div class="row pb-4">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              Total Assets (000s):
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.total_assets }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asTotalAsset.min.$error }"
                  v-model="asTotalAsset.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >$</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asTotalAsset.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asTotalAsset.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asTotalAsset.max.$error }"
                  v-model="asTotalAsset.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >$</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asTotalAsset.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asTotalAsset.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              Deposit Accounts:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.deposit_accounts }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asDepositAccount.min.$error }"
                  v-model="asDepositAccount.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asDepositAccount.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositAccount.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asDepositAccount.max.$error }"
                  v-model="asDepositAccount.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asDepositAccount.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asDepositAccount.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              Branches:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.branches }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asBranches.min.$error }"
                  v-model="asBranches.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asBranches.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asBranches.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asBranches.min.$error }"
                  v-model="asBranches.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asBranches.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asBranches.min.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-4 field-label">
              Charter:
            </div>
            <div class="col-sm-8 field-label-value text-right">
              {{ asProfileValues.charter }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <Multiselect
                v-model="asCharters"
                :options="asCharterList"
                :multiple="true"
                :close-on-select="false"
                label="label"
                track-by="value"
                :showLabels="false"
                placeholder=""
                class="simple-select form-control-multi cpg-multi-select"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }}
                  {{ values.length | pluralize('charter') }} selected
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-4" v-if="isCreditUnion">
          <div class="row">
            <div class="col-sm-6 pr-0 field-label">
              Type of Membership:
            </div>
            <div
              class="col-sm-6 field-label-value text-right longer-label-value"
              :title="asProfileValues.type_of_membership"
            >
              {{ asProfileValues.type_of_membership }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-select
                :options="asTypeOfMembershipsList"
                v-model="asTypeOfMembership"
              >
                <template slot="first">
                  <option value="">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-4" v-else>
          <div class="row">
            <div class="col-sm-6 field-label">
              Sub-S Election:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.subchapters }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-select
                :disabled="selectedFI.fi_type !== 'bank'"
                :options="asSubCharterList"
                v-model="asSubCharter"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-4" v-if="isCreditUnion">
          <div class="row">
            <div class="col-sm-9 field-label">
              Low Income Designation:
            </div>
            <div class="col-sm-3 field-label-value text-right">
              {{ asProfileValues.low_income_designation }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-select
                :options="asLowIncomeDesignationsList"
                v-model="asLowIncomeDesignation"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-4" v-else>
          <div class="row">
            <div class="col-sm-6 field-label">
              Minority-Owned:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.minority_owned }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-select
                :disabled="selectedFI.fi_type !== 'bank'"
                :options="asMinorityOwnedList"
                v-model="asMinorityOwned"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              FI Specialty Type:
            </div>
            <div class="col-sm-6 pl-0 field-label-value text-right">
              {{ asProfileValues.fi_specialty_type }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <Multiselect
                v-model="asFiSpecialtyTypes"
                :options="asFiSpecialtyTypeList"
                :multiple="true"
                :close-on-select="false"
                :showLabels="false"
                placeholder=""
                class="simple-select form-control-multi cpg-multi-select"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }} selected
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              Geography:
            </div>
            <div
              class="col-sm-6 field-label-value text-right"
              v-if="asProfileValues.geography"
            >
              <div v-if="asGeographyType === 'regions'">
                {{ asProfileValues.geography.region }}
              </div>
              <div v-else>
                {{ asProfileValues.geography.state }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-form-select
                @change="resetGeoData"
                v-model="asGeographyType"
                :options="asGeographyTypesList"
              >
              </b-form-select>
            </div>

            <div class="col-sm-6 pl-0" v-if="asGeographyType === 'regions'">
              <Multiselect
                v-model="asRegions"
                :options="asRegionList"
                :multiple="true"
                :close-on-select="false"
                label="name"
                track-by="code"
                :showLabels="false"
                placeholder=""
                :class="[
                  { 'is-invalid': $v.asRegions.$error },
                  'simple-select form-control-multi'
                ]"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }} selected
                </template>
              </Multiselect>
            </div>

            <div class="col-sm-6 pl-0" v-else>
              <Multiselect
                v-model="asStates"
                :options="asStateList"
                :multiple="true"
                :close-on-select="false"
                label="name"
                track-by="code"
                :showLabels="false"
                placeholder=""
                :class="[
                  { 'is-invalid': $v.asStates.$error },
                  'simple-select form-control-multi'
                ]"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }} selected
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-4" v-if="!isBHC">
          <div class="row">
            <div class="col-sm-6 field-label">
              Market Coverage:
            </div>
            <div class="col-sm-6 pl-0 field-label-value text-right">
              {{ asProfileValues.market_coverage }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <Multiselect
                v-model="asMarketCoverage"
                :options="asMarketCoverageList"
                :multiple="true"
                :close-on-select="false"
                :showLabels="false"
                placeholder=""
                class="simple-select form-control-multi cpg-multi-select"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }} selected
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              FI Age (Years):
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.fi_age }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asAge.min.$error }"
                  v-model="asAge.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asAge.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asAge.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asAge.max.$error }"
                  v-model="asAge.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asAge.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asAge.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>

        <div class="col-sm-4" v-if="!isBHC">
          <div class="row">
            <div class="col-sm-6 field-label">
              Market Growth:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.market_growth }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketGrowth.min.$error }"
                  v-model="asMarketGrowth.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketGrowth.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketGrowth.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketGrowth.max.$error }"
                  v-model="asMarketGrowth.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketGrowth.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketGrowth.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>

        <div class="col-sm-4" v-if="!isBHC">
          <div class="row">
            <div class="col-sm-5 field-label">
              Market Size:
            </div>
            <div class="col-sm-7 pl-0 field-label-value text-right">
              {{ asProfileValues.market_size }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <Multiselect
                v-model="asMarketSize"
                :options="asMarketSizeList"
                :multiple="true"
                :close-on-select="false"
                :showLabels="false"
                placeholder=""
                class="simple-select form-control-multi cpg-multi-select"
                :searchable="true"
              >
                <template slot="selection" slot-scope="{ values }">
                  {{ values.length }} selected
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-4 pr-0 field-label">
              FI Age Group:
            </div>
            <div class="col-sm-8 field-label-value text-right">
              {{ asProfileValues.fi_age_group }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" v-html="'&nbsp;'"></div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-select :options="asAgeGroupList" v-model="asAgeGroup">
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>

        <div class="col-sm-4" v-if="!isBHC">
          <div class="row">
            <div class="col-sm-6 field-label">
              Market Share:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.market_share }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">MIN</div>
            <div class="col-sm-6 field-sub-label">MAX</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketShare.min.$error }"
                  v-model="asMarketShare.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketShare.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketShare.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketShare.max.$error }"
                  v-model="asMarketShare.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketShare.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketShare.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>

        <div class="col-sm-4" v-if="!isBHC">
          <div class="row">
            <div class="col-sm-6 field-label">
              Market Rank:
            </div>
            <div class="col-sm-6 field-label-value text-right">
              {{ asProfileValues.market_rank }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 field-sub-label">Highest</div>
            <div class="col-sm-6 field-sub-label">Lowest</div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketRank.min.$error }"
                  v-model="asMarketRank.min"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketRank.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketRank.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.asMarketRank.max.$error }"
                  v-model="asMarketRank.max"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >#</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.asMarketRank.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.asMarketRank.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card-body>
</template>

<script>
import { decimal } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import Multiselect from 'vue-multiselect'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const isValidRange = (min, max) => {
  let minVal = parseFloat(min)
  let maxVal = parseFloat(max)
  if (isNaN(minVal) || isNaN(maxVal)) {
    return true
  }
  return minVal <= maxVal
}

export default {
  name: 'AdvancedSearchCPGProfile',
  components: {
    Multiselect
  },
  props: {
    selectedFI: {
      required: true,
      type: Object
    },
    validateForm: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asStateList: state => state.asStateList,
      asRegionList: state => state.asRegionList,
      asFiSpecialtyTypeList: state => state.asFiSpecialtyTypeList,
      asCharterList: state => state.asCharterList,
      asSubCharterList: state => state.asSubCharterList,
      asMinorityOwnedList: state => state.asMinorityOwnedList,
      asMarketCoverageList: state => state.asMarketCoverageList,
      asMarketSizeList: state => state.asMarketSizeList,
      asGeographyTypesList: state => state.asGeographyTypesList,
      asAgeGroupList: state => state.asAgeGroupList,
      asTypeOfMembershipsList: state => state.asTypeOfMembershipsList,
      asLowIncomeDesignationsList: state => state.asLowIncomeDesignationsList,
      asProfileValues: state => state.asProfileValues
    }),
    ...mapFields([
      'asTotalAsset',
      'asDepositAccount',
      'asBranches',
      'asCharters',
      'asSubCharter',
      'asMinorityOwned',
      'asGeographyType',
      'asStates',
      'asRegions',
      'asFiSpecialtyTypes',
      'asMarketCoverage',
      'asMarketSize',
      'asMarketShare',
      'asMarketRank',
      'asMarketGrowth',
      'asAge',
      'asAgeGroup',
      'asTypeOfMembership',
      'asLowIncomeDesignation'
    ]),
    isCreditUnion() {
      return this.selectedFI.fi_type === 'credit_union'
    },
    isBHC() {
      return this.selectedFI.fi_type === 'bhc'
    }
  },
  methods: {
    ...mapMutations('CustomPeerGroup', ['setASProfileFormValidity']),
    resetGeoData(type) {
      if (type === 'states') {
        this.asRegions = []
      } else {
        this.asStates = []
      }
    }
  },
  watch: {
    validateForm(newVal) {
      if (newVal === true) {
        this.$v.$touch()
        this.setASProfileFormValidity(!this.$v.$invalid)
        this.$emit('profileValidity', !this.$v.$invalid)
      }
    }
  },
  validations: {
    asTotalAsset: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asTotalAsset.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asTotalAsset.max, val)
        }
      }
    },
    asDepositAccount: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asDepositAccount.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asDepositAccount.min, val)
        }
      }
    },
    asBranches: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asBranches.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asBranches.min, val)
        }
      }
    },
    asAge: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asAge.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asAge.min, val)
        }
      }
    },
    asMarketShare: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asMarketShare.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asMarketShare.min, val)
        }
      }
    },
    asMarketGrowth: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asMarketGrowth.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asMarketGrowth.min, val)
        }
      }
    },
    asMarketRank: {
      min: {
        decimal,
        isBefore(val) {
          return isValidRange(val, this.asMarketRank.max)
        }
      },
      max: {
        decimal,
        isAfter(val) {
          return isValidRange(this.asMarketRank.min, val)
        }
      }
    },
    asStates: {},
    asRegions: {}
  }
}
</script>

<style lang="scss" scoped></style>
