var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","id":"peer-group-share","title":_vm.modalTitle,"modal-class":"peer-group-share","hide-footer":""},on:{"show":_vm.fetchSharedGroups}},[_c('v-wait',{attrs:{"for":"fetchingSharedGroups"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":15}})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"}),_c('div',{staticClass:"col-sm-8"},[_c('div',[_vm._v(" Use the tool below to share peer group "),_c('b',[_vm._v(_vm._s(_vm.peerGroup.name))]),_vm._v(" among other user groups. ")])])]),_c('div',{staticClass:"row list-group-container pt-0"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"}),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"row card-sub-header mt-0"},[_c('div',{staticClass:"col-sm-5 card-sub-header"},[_vm._v(" User Groups ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2 align-self-center pl-3 pr-0 tip-text"},[_c('b',[_c('i',[_vm._v(" Click to share Peer Group ")])])]),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"full-border"},[_c('div',{staticClass:"filter-result p-0"},[_c('b-card',{staticClass:"text-center",attrs:{"body-class":"p-0"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_c('b-card',{staticClass:"text-left"},[_c('b-list-group',_vm._l((_vm.userGroups),function(group,index){return _c('div',{key:index},[_c('b-list-group-item',{class:{
                                  selected:
                                    group.id === _vm.selectedGroup &&
                                    _vm.selectedGroup.id,
                                  'px-1': true
                                },on:{"click":function($event){return _vm.selectGroup(group)}}},[_vm._v(" "+_vm._s(group.name)+" "),_c('span',{staticClass:"float-right fa fa-check pt-1 add-icon"})])],1)}),0)],1)],1)])],1)],1)],1)])])])])]),_c('div',{staticClass:"row list-group-container pb-1 pt-0"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"}),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"row card-sub-header mt-0"},[_c('div',{staticClass:"col-sm-5 card-sub-header"},[_vm._v(" Shared Groups ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2 align-self-center pl-3 pr-0 tip-text"},[_c('b',[_c('i',[_vm._v(" Click to remove from user group ")])])]),_c('div',{staticClass:"col-sm-8"},[_c('div',[_c('div',{staticClass:"selected-result rounded form-control p-0"},[_c('b-card',{staticClass:"text-center",attrs:{"body-class":"p-0"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_c('b-card',{staticClass:"text-left"},[_c('b-list-group',_vm._l((_vm.selectedGroups),function(group,index){return _c('div',{key:index},[_c('b-list-group-item',{class:{
                                  selected:
                                    group.id === _vm.selectedGroup &&
                                    _vm.selectedGroup.id,
                                  'px-1': true
                                },on:{"~click":function($event){return _vm.removeGroup(group)}}},[_vm._v(" "+_vm._s(group.name)+" "),_c('span',{staticClass:"float-right fa fa-trash pt-1 remove-icon"})])],1)}),0)],1)],1)])],1)],1)],1)])])])])]),_c('div',{staticClass:"flex-row align-items-top"},[_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"list-group-container"},[_c('b-col',{attrs:{"sm":"2"}}),_c('b-col',{staticClass:"text-center",attrs:{"sm":"8"}},[_c('b-button',{staticClass:"fw-btn mx-3",attrs:{"size":"","variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v("Close")]),_c('b-button',{staticClass:"fw-btn-ext mx-3",attrs:{"size":"","variant":"primary"},on:{"click":_vm.sharePeerGroup}},[_vm._v("Share Peer Group")])],1)],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }