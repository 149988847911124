<template>
  <div class="row pb-3">
    <div class="col-sm-7">
      <div class="row list-group-container">
        <div class="col-sm-12">
          <div class="row mt-0">
            <div class="col-sm-2 pr-0"></div>
            <div class="col-sm-5 card-sub-header">
              <span v-if="institutions.length">
                Search Results:
                <i>{{ institutions.length | numberFormat }} FIs</i>
              </span>
              <span v-else>
                Search Results
              </span>
            </div>
            <div class="col-sm-5 text-right pr-0 align-self-center">
              <b-button
                size="sm"
                variant="primary"
                class="mx-3 font-weight-bold"
                @click="addAll"
                >Add All</b-button
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-2 pr-0 align-self-center tip-text">
              <b> <i> Click to Add to Peer Group </i> </b>
            </div>
            <div class="col-sm-10">
              <div class="full-border">
                <div class="filter-result">
                  <b-card class="text-center" body-class="p-0">
                    <b-row>
                      <b-col md="12">
                        <div>
                          <b-card class="text-left">
                            <b-list-group
                              v-if="emptyInstitutions"
                              class="text-center"
                            >
                              <b-list-group-item>
                                <i>No Institutions found ...</i>
                              </b-list-group-item>
                            </b-list-group>
                            <b-list-group v-else>
                              <div
                                v-for="institution in institutions"
                                :key="institution.id"
                              >
                                <b-list-group-item
                                  @click="setInstitution(institution)"
                                  :class="{
                                    selected:
                                      institution.id === selectedInstitution.id
                                  }"
                                >
                                  {{ institution.full_name }}&#44;
                                  {{ institution.city }}&#44;
                                  {{ institution.state }}
                                  {{ '[' + institution.id + ']' }}
                                  <span
                                    class="float-right fa fa-check pt-1 add-icon"
                                  ></span>
                                </b-list-group-item>
                              </div>
                            </b-list-group>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row list-group-cotainer pt-3">
        <div class="col-sm-12">
          <div class="row card-sub-header mt-0">
            <div class="col-sm-2 pr-0"></div>
            <div class="col-sm-5 card-sub-header">
              <span v-if="selectedInstitutions.length">
                Peer Group Members:
                <i>{{ selectedInstitutions.length | numberFormat }} FIs</i>
              </span>
              <span v-else>
                Peer Group Members
              </span>
            </div>
            <div class="col-sm-5 text-right pr-0 align-self-center">
              <b-button
                size="sm"
                variant="secondary"
                class="mx-3 font-weight-bold"
                @click="removeAll"
                >Remove All</b-button
              >
            </div>
          </div>
        </div>

        <div class="col-sm-2 pr-0 align-self-center tip-text">
          <b> <i> Click to Remove from Peer Group </i> </b>
        </div>
        <div class="col-sm-10">
          <div>
            <div
              class="selected-result rounded form-control p-0"
              :class="{
                'is-invalid': $v.selectedInstitutions.$error,
                'is-valid': !$v.selectedInstitutions.$invalid
              }"
            >
              <b-card class="text-center" body-class="p-0">
                <b-row>
                  <b-col md="12">
                    <div>
                      <b-card class="text-left">
                        <b-list-group>
                          <div
                            v-for="institution in selectedInstitutions"
                            :key="institution.id"
                          >
                            <b-list-group-item
                              v-on:click.once="removeInstitution(institution)"
                              :class="{
                                selected:
                                  institution.id === selectedInstitution.id
                              }"
                            >
                              {{ institution.full_name }}&#44;
                              {{ institution.city }}&#44;
                              {{ institution.state }}
                              {{ '[' + institution.id + ']' }}
                              <span
                                class="float-right fa fa-trash pt-1 remove-icon"
                              ></span>
                            </b-list-group-item>
                          </div>
                        </b-list-group>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-form-invalid-feedback v-if="!$v.selectedInstitutions.required"
              >Peer Group members can't be blank</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              class="larger-font"
              v-if="!$v.selectedInstitutions.belowMaxLimit"
            >
              <b>Peer Group Members count cannot exceed 2000.</b>
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-5 mt-3">
      <div class="row">
        <div class="col-sm-12">
          <b-form-group>
            <label for="Search" class="mb-0">
              <b>Peer Group Name</b>
            </label>
            <b-form-input
              type="text"
              class="form-control rounded"
              placeholder="Enter a Peer Group Name (required)"
              v-model="pgName"
              :class="{
                'is-invalid': $v.pgName.$error,
                'is-valid': !$v.pgName.$invalid
              }"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.pgName.required"
              >Peer Group name can't be blank</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <b-form-group>
            <label for="Search" class="mb-1">
              <b>Peer Group Description</b>
            </label>
            <b-form-textarea
              id="description"
              class="form-control rounded"
              v-model="pgDescription"
              rows="3"
              max-rows="10"
            >
            </b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <b-form-group>
            <label for="Search" class="mb-1">
              <b>Groups Shared</b>
            </label>
            <multiselect
              v-model="sharedGroups"
              :options="userGroups"
              :multiple="true"
              :close-on-select="false"
              label="name"
              track-by="id"
              :showLabels="false"
              placeholder
              class="simple-select"
              :searchable="false"
            >
              <template slot="selection" slot-scope="{ values }"
                >{{ values.length }}
                {{ values.length | pluralize('group') }}
                selected</template
              >
            </multiselect>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 pt-3 text-center">
          <b-button
            size
            variant="secondary"
            class="fw-btn mx-2"
            @click="closeModel"
            >Close</b-button
          >
          <b-button
            size=""
            variant="primary"
            class="fw-btn-ext mx-2"
            @click="savePeerGroup"
            :disabled="submittingForm"
            v-if="!isEdit"
          >
            Save & Create Peer Group
          </b-button>
          <b-button
            size=""
            variant="primary"
            class="fw-btn-ext mx-3"
            :disabled="submittingForm"
            @click="updatePeerGroup"
            v-else
          >
            Update Peer Group
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 pt-3 text-center">
          <b-button
            size
            variant="primary"
            class=""
            :disabled="downloading"
            @click="downloadPeers"
            >Export Peer Group Members</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import downloadsAPI from '@/modules/core/api/downloads'
import Multiselect from 'vue-multiselect'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

export default {
  name: 'AdvancedSearchCPGSearchResults',
  components: {
    Multiselect
  },
  props: {
    institutions: {
      required: true,
      type: Array
    },
    selectedFI: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    tabIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedInstitution: {},
      submittingForm: false,
      downloading: false
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      selectedInstitutions: state => state.selectedInstitutions,
      peerGroup: state => state.peerGroup,
      qsSelectAllResults: state => state.qsSelectAllResults,
      asSelectAllResults: state => state.asSelectAllResults
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapFields(['sharedGroups']),
    pgName: {
      get: function() {
        return this.peerGroup.name
      },
      set: function(newVal) {
        return this.setPeerGroup({ name: newVal })
      }
    },
    pgDescription: {
      get: function() {
        return this.peerGroup.description
      },
      set: function(newVal) {
        return this.setPeerGroup({ description: newVal })
      }
    },
    selectAllFIs: {
      get: function() {
        return this.tabIndex === 1
          ? this.qsSelectAllResults
          : this.asSelectAllResults
      },
      set: function(newVal) {
        if (this.tabIndex === 1) {
          return this.setQSSelectAllResults(newVal)
        } else {
          return this.setASSelectAllResults(newVal)
        }
      }
    },
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    selectedInstitutionIds() {
      return this.selectedInstitutions.map(si => si.id)
    },
    isEdit() {
      return this.type === 'edit'
    }
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setSelectedInstitutions',
      'setPeerGroup',
      'setQSSelectAllResults',
      'setASSelectAllResults'
    ]),
    setInstitution: function(institution) {
      this.selectedInstitution = institution
      if (!this.selectedInstitutionIds.includes(institution.id)) {
        let selectedIns = this.selectedInstitutions
        selectedIns.unshift(institution)
        this.setSelectedInstitutions(selectedIns)
      }
    },
    removeInstitution: function(institution) {
      let selectedIns = this.selectedInstitutions
      selectedIns.splice(selectedIns.indexOf(institution), 1)
      this.setSelectedInstitutions(selectedIns)
    },
    closeModel: function() {
      this.$emit('closeModal')
    },
    clearData() {
      this.setSelectedInstitutions([])
    },
    canSavePeerGroup() {
      this.$v.pgName.$touch()
      this.$v.selectedInstitutions.$touch()
      return !this.$v.pgName.$invalid && !this.$v.selectedInstitutions.$invalid
    },
    savePeerGroup() {
      if (this.canSavePeerGroup()) {
        this.submittingForm = true

        this.$http
          .post(
            '/api/peer_groups',
            {
              bank_ids: this.selectedInstitutionIds,
              peer_group: {
                name: this.pgName,
                description: this.pgDescription,
                group_peer_groups_attributes: this.sharedGroups.map(group => {
                  return { group_id: group.id, peer_group_access_id: 2 }
                })
              },
              bank_id: this.selectedFI.id
            },
            { handleErrors: true }
          )
          .then(
            res => {
              this.$toasted.show('Peer Group created successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.$ahoy.track('peer_group_created', {
                peer_group_id: res.data.peer_group_id
              })
              this.clearData()
              this.$emit('pgCreated')
            },
            error => {
              if (error.response) {
                this.$toasted.show(error.response.data.message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
            }
          )
          .then(() => {
            this.submittingForm = false
          })
      }
    },
    updatePeerGroup() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submittingForm = true

        this.$http
          .patch(
            `/api/peer_groups/${this.peerGroup.id}`,
            {
              bank_ids: this.selectedInstitutionIds,
              group_ids: this.sharedGroups.map(group => group.id),
              peer_group: {
                id: this.peerGroup.id,
                name: this.pgName,
                description: this.pgDescription
              },
              bank_id: this.selectedFI.id
            },
            { handleErrors: true }
          )
          .then(
            () => {
              this.$toasted.show('Peer Group updated successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.clearData()
              this.$emit('pgUpdated')
            },
            error => {
              if (error.response) {
                this.$toasted.show(error.response.data.message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
            }
          )
          .then(() => {
            this.submittingForm = false
          })
      }
    },
    addAll() {
      let institutionsToAdd = _.unionBy(
        this.institutions,
        this.selectedInstitutions,
        'id'
      )
      this.setSelectedInstitutions(institutionsToAdd)
    },
    removeAll() {
      this.setSelectedInstitutions([])
    },
    downloadPeers() {
      if (!this.selectedInstitutions.length) {
        this.$toasted.show('No Peer Group Members to download', {
          icon: 'key',
          type: 'error'
        })
        return true
      }

      this.$nextTick(function() {
        this.downloading = true
      })

      this.$http
        .post(
          '/downloads/peer_group_members.xlsx',
          {
            downloads: {
              institution_ids: this.selectedInstitutions.map(fi => fi.id)
            }
          },
          {
            handleErrors: true,
            responseType: 'blob'
          }
        )
        .then(res => {
          downloadsAPI.downloadResult(res, 'peer_group_members.xlsx')
          this.$toasted.show('Peer Group Members successfully downloaded.', {
            icon: 'key',
            type: 'success'
          })
        })
        .finally(() => {
          this.$nextTick(function() {
            this.downloading = false
          })
        })
    }
  },
  validations: {
    pgName: {
      required
    },
    selectedInstitutions: {
      required,
      belowMaxLimit(value) {
        return value && value.length <= 2000
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.tip-text {
  color: #808080;
  font-weight: 350;
}
.larger-font {
  font-size: 100%;
}
</style>
