var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasLogo)?_c('div',{ref:"headerLogo",class:[
    _vm.manageSuperAdminBoard ? 'cursor-pointer' : '',
    'header-logo text-right company-logo'
  ],on:{"click":_vm.manageLogo}}):_c('div',{ref:"headerLogo",class:[
    _vm.manageSuperAdminBoard ? 'cursor-pointer' : '',
    _vm.isVendor ? 'vendor-default' : 'fi-default',
    'header-logo text-right logo-default'
  ],attrs:{"id":"defaultLogo"},on:{"click":_vm.manageLogo}})}
var staticRenderFns = []

export { render, staticRenderFns }