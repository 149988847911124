<template>
  <b-card-body class="p-3">
    <b-form autocomplete="off">
      <div class="row">
        <div class="col-sm-7">
          <div class="row">
            <div class="col-sm-12 field-label">
              Profit:
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-3 field-sub-label">MIN</div>
            <div class="col-sm-3 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(profitMetric, index) in asProfit"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="profitMetric.id"
                  @input="profitMetricUpdate(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="profit.id"
                    v-for="(profit, index) in asProfitList"
                    :key="index"
                    >{{ profit.name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asProfit.$each.$iter[index].min.$error
                  }"
                  v-model="profitMetric.min"
                  :disabled="!profitMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asProfit.$each.$iter[index].max.$error
                  }"
                  v-model="profitMetric.max"
                  :disabled="!profitMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asProfit.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{
                profitMetric.id && asPerformanceValues.profit[profitMetric.id]
              }}
            </div>
          </div>
        </div>
        <div class="col-sm-5" v-if="selectedFI.fi_type !== 'bhc'">
          <div class="row">
            <div class="col-sm-12" v-html="'&nbsp;'"></div>
          </div>
          <div class="row">
            <div class="col-sm-12 field-sub-label" v-html="'&nbsp;'"></div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Profit Opportunity:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asProfitOpportunity"
                :options="asProfitOpportunityList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Profit Strength:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asProfitStrength"
                :options="asProfitStrengthList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-sm-7">
          <div class="row">
            <div class="col-sm-12 field-label">
              Growth:
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-3 field-sub-label">MIN</div>
            <div class="col-sm-3 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(growthMetric, index) in asGrowth"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="growthMetric.id"
                  @input="growthMetricUpdate(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="growth.id"
                    v-for="(growth, index) in asGrowthList"
                    :key="index"
                    >{{ growth.name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asGrowth.$each.$iter[index].min.$error
                  }"
                  v-model="growthMetric.min"
                  :disabled="!growthMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asGrowth.$each.$iter[index].max.$error
                  }"
                  v-model="growthMetric.max"
                  :disabled="!growthMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asGrowth.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{
                growthMetric.id && asPerformanceValues.growth[growthMetric.id]
              }}
            </div>
          </div>
        </div>

        <div class="col-sm-5" v-if="selectedFI.fi_type !== 'bhc'">
          <div class="row">
            <div class="col-sm-12" v-html="'&nbsp;'"></div>
          </div>
          <div class="row">
            <div class="col-sm-12 field-sub-label" v-html="'&nbsp;'"></div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Growth Opportunity:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asGrowthOpportunity"
                :options="asGrowthOpportunityList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Growth Strength:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asGrowthStrength"
                :options="asGrowthStrengthList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-sm-7">
          <div class="row">
            <div class="col-sm-12 field-label">
              Risk:
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-3 field-sub-label">MIN</div>
            <div class="col-sm-3 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(riskMetric, index) in asRisk"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="riskMetric.id"
                  @input="riskMetricUpdate(index, riskMetric.id)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="risk.id"
                    v-for="(risk, index) in asRiskList"
                    :key="index"
                    >{{ risk.name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asRisk.$each.$iter[index].min.$error
                  }"
                  v-model="riskMetric.min"
                  :disabled="!riskMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append">
                  <span class="input-group-text font-weight-bold">{{
                    riskMetricSymbol[index]
                  }}</span>
                </b-input-group-addon>
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asRisk.$each.$iter[index].max.$error
                  }"
                  v-model="riskMetric.max"
                  :disabled="!riskMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append">
                  <span class="input-group-text font-weight-bold">{{
                    riskMetricSymbol[index]
                  }}</span>
                </b-input-group-addon>
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asRisk.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{ riskMetric.id && asPerformanceValues.risk[riskMetric.id] }}
            </div>
          </div>
        </div>

        <div class="col-sm-5" v-if="selectedFI.fi_type !== 'bhc'">
          <div class="row">
            <div class="col-sm-12" v-html="'&nbsp;'"></div>
          </div>
          <div class="row">
            <div class="col-sm-12 field-sub-label" v-html="'&nbsp;'"></div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Risk Opportunity:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asRiskOpportunity"
                :options="asRiskOpportunityList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-6 field-label align-self-center pr-0">
              Primary Risk Strength:
            </div>
            <div class="col-sm-6">
              <b-form-select
                v-model="asRiskStrength"
                :options="asRiskStrengthList"
              >
                <template slot="first">
                  <option :value="null">Please Select</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card-body>
</template>

<script>
import { decimal, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'

const { mapMultiRowFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const isValidRange = (min, max) => {
  let minVal = parseFloat(min)
  let maxVal = parseFloat(max)
  if (isNaN(minVal) || isNaN(maxVal)) {
    return true
  }
  return minVal <= maxVal
}

export default {
  name: 'AdvancedSearchCPGPerformance',
  props: {
    validateForm: {
      required: true,
      type: Boolean
    },
    selectedFI: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      riskMetricSymbol: ['%', '%', '%']
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asPerformanceValues: state => state.asPerformanceValues,
      asProfitList: state => state.asProfitList,
      asGrowthList: state => state.asGrowthList,
      asRiskList: state => state.asRiskList,
      asProfitOpportunityList: state => state.asProfitOpportunityList,
      asProfitStrengthList: state => state.asProfitStrengthList,
      asGrowthOpportunityList: state => state.asGrowthOpportunityList,
      asGrowthStrengthList: state => state.asGrowthStrengthList,
      asRiskOpportunityList: state => state.asRiskOpportunityList,
      asRiskStrengthList: state => state.asRiskStrengthList
    }),
    ...mapFields([
      'asProfitOpportunity',
      'asProfitStrength',
      'asGrowthOpportunity',
      'asGrowthStrength',
      'asRiskOpportunity',
      'asRiskStrength'
    ]),
    ...mapMultiRowFields(['asProfit', 'asGrowth', 'asRisk'])
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setASPerformanceFormValidity',
      'resetASProfitMetricData',
      'resetASGrowthMetricData',
      'resetASRiskMetricData'
    ]),
    profitMetricUpdate(index) {
      if (!this.asProfit[index].id) {
        this.resetASProfitMetricData(index)
      }
    },
    growthMetricUpdate(index) {
      if (!this.asGrowth[index].id) {
        this.resetASGrowthMetricData(index)
      }
    },
    riskMetricUpdate(index, riskMetricId) {
      let selectedRisk = this.asRiskList.find(risk => risk.id === riskMetricId)
      this.riskMetricSymbol[index] = selectedRisk ? selectedRisk.symbol : '%'
      if (!this.asRisk[index].id) {
        this.resetASRiskMetricData(index)
      }
    }
  },
  validations: {
    asProfit: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asProfit) {
            return asProfit.id && !asProfit.max
          }),
          isBefore(val, asProfit) {
            return isValidRange(val, asProfit.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asProfit) {
            return asProfit.id && !asProfit.min
          }),
          isAfter(val, asProfit) {
            return isValidRange(asProfit.min, val)
          }
        }
      }
    },
    asGrowth: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asGrowth) {
            return asGrowth.id && !asGrowth.max
          }),
          isBefore(val, asGrowth) {
            return isValidRange(val, asGrowth.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asGrowth) {
            return asGrowth.id && !asGrowth.min
          }),
          isAfter(val, asGrowth) {
            return isValidRange(asGrowth.min, val)
          }
        }
      }
    },
    asRisk: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asRisk) {
            return asRisk.id && !asRisk.max
          }),
          isBefore(val, asRisk) {
            return isValidRange(val, asRisk.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asRisk) {
            return asRisk.id && !asRisk.min
          }),
          isAfter(val, asRisk) {
            return isValidRange(asRisk.min, val)
          }
        }
      }
    }
  },
  watch: {
    validateForm(newVal) {
      if (newVal) {
        this.$v.$touch()
        this.setASPerformanceFormValidity(!this.$v.$invalid)
        this.$emit('performanceValidity', !this.$v.$invalid)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
