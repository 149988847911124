<template>
  <b-card-body class="py-3 px-5">
    <b-form>
      <div class="row">
        <div class="col-sm-12">
          <div class="field-label pb-2">
            Technographic Vendor / Product:
          </div>
          <div class="row">
            <div class="col-sm-3 field-sub-label">
              Technographic
            </div>
            <div class="col-sm-3 field-sub-label">
              Vendor
            </div>
            <div class="col-sm-3 field-sub-label">
              Product
            </div>
          </div>

          <div
            class="row pb-2"
            v-for="(offering, index) in asOffering"
            :key="index"
          >
            <div class="col-sm-3">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="offering.bank_service_id"
                  @input="fetchVendors(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="tg.id"
                    v-for="(tg, index) in asTechnographicsList"
                    :key="index"
                    >{{ tg.name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div
              class="col-sm-3"
              :class="{ loading: vendorLoadingIndex === index }"
            >
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="offering.vendor_id"
                  @input="fetchProducts(index)"
                  :disabled="!offering.bank_service_id"
                  :class="{
                    'is-invalid':
                      $v.asOffering.$each.$iter[index].vendor_id.$error
                  }"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="vendor.vendor_id"
                    v-for="(vendor, index) in vendorList[index]"
                    :key="index"
                    >{{ vendor.vendor }}</option
                  >
                </b-form-select>
                <b-form-invalid-feedback
                  v-if="!$v.asOffering.$each.$iter[index].vendor_id.required"
                  >vendor required</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div
              class="col-sm-3"
              :class="{
                loading:
                  vendorLoadingIndex === index || productLoadingIndex === index
              }"
            >
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="offering.product_id"
                  :disabled="!offering.vendor_id"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="product.product_id"
                    v-for="(product, index) in productList[index]"
                    :key="index"
                    >{{ product.product }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-3 align-self-center">
              {{
                offering.bank_service_id &&
                  asOfferingValues[offering.bank_service_id]
              }}
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card-body>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'

const { mapMultiRowFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})
export default {
  name: 'AdvancedSearchCPGOffering',
  props: {
    validateForm: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      vendorLoadingIndex: null,
      productLoadingIndex: null
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asOfferingValues: state => state.asOfferingValues,
      asTechnographicsList: state => state.asTechnographicsList,
      asProfitList: state => state.asProfitList,
      vendorList: state => state.asOfferingVendorList,
      productList: state => state.asOfferingProductList
    }),
    ...mapMultiRowFields(['asOffering'])
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setASOfferingFormValidity',
      'setASOfferingVendors',
      'setASOfferingProducts',
      'resetASOfferingVendorList',
      'resetASOfferingProductList',
      'resetOfferingVendor',
      'resetOfferingProduct'
    ]),
    fetchVendors(index) {
      this.vendorLoadingIndex = index
      this.resetOfferingVendor(index)
      this.resetOfferingProduct(index)
      this.resetASOfferingVendorList(index)
      this.resetASOfferingProductList(index)

      let selectedBankServiceId = this.asOffering[index].bank_service_id

      if (selectedBankServiceId) {
        this.$http
          .get('/api/fi_advanced_search/vendors_list', {
            params: {
              bank_service_id: selectedBankServiceId
            },
            handleErrors: true
          })
          .then(res => {
            this.setASOfferingVendors({
              index: index,
              vendors: res.data.vendors
            })
          })
          .then(() => {
            this.vendorLoadingIndex = null
          })
      }
    },
    fetchProducts(index) {
      this.productLoadingIndex = index
      this.resetOfferingProduct(index)
      this.resetASOfferingProductList(index)

      let selectedVendorId = this.asOffering[index].vendor_id

      if (selectedVendorId) {
        this.$http
          .get('/api/fi_advanced_search/products_list', {
            params: {
              bank_service_id: this.asOffering[index].bank_service_id,
              vendor_id: selectedVendorId
            },
            handleErrors: true
          })
          .then(res => {
            this.setASOfferingProducts({
              index: index,
              products: res.data.products
            })
          })
          .then(() => {
            this.productLoadingIndex = null
          })
      }
    }
  },
  validations: {
    asOffering: {
      $each: {
        vendor_id: {
          required: requiredIf(function(asOffering) {
            return asOffering.bank_service_id
          })
        }
      }
    }
  },
  watch: {
    validateForm(newVal) {
      if (newVal) {
        this.$v.$touch()
        this.setASOfferingFormValidity(!this.$v.$invalid)
        this.$emit('offeringValidity', !this.$v.$invalid)
      }
    }
  }
}
</script>
<style lang="scss">
.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
