<template>
  <b-card-body class="py-3 px-5">
    <b-form autocomplete="off">
      <div class="row">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-12 field-label mb-2">
              Strategic Focus:
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              Loan Focus:
            </div>
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asLoanFocus"
                  :options="asLoanFocusList"
                  :multiple="true"
                  :close-on-select="false"
                  label="label"
                  track-by="value"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-4 align-self-center">
              {{
                asStrategyValues.strategic_focus &&
                  asStrategyValues.strategic_focus.loan_focus
              }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              Funding Focus:
            </div>
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asFundingFocus"
                  :options="asFundingFocusList"
                  :multiple="true"
                  :close-on-select="false"
                  label="label"
                  track-by="value"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-4 align-self-center">
              {{
                asStrategyValues.strategic_focus &&
                  asStrategyValues.strategic_focus.funding_focus
              }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              OBS Focus:
            </div>
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asOBSFocus"
                  :options="asOBSFocusList"
                  :multiple="true"
                  :close-on-select="false"
                  label="label"
                  track-by="value"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-4 align-self-center">
              {{
                asStrategyValues.strategic_focus &&
                  asStrategyValues.strategic_focus.obs_focus
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-12 field-label">
              Loan Mix (% of Total Loans):
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-2 field-sub-label">MIN</div>
            <div class="col-sm-2 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(loanMetric, index) in asLoanMix"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="loanMetric.id"
                  @input="loanMetricUpdate(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="loanMix.id"
                    v-for="(loanMix, index) in asLoanMixList"
                    :key="index"
                    >{{ loanMix.statement_name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asLoanMix.$each.$iter[index].min.$error
                  }"
                  v-model="loanMetric.min"
                  :disabled="!loanMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asLoanMix.$each.$iter[index].max.$error
                  }"
                  v-model="loanMetric.max"
                  :disabled="!loanMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asLoanMix.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{ loanMetric.id && asStrategyValues.loan_mix[loanMetric.id] }}
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-12 field-label">
              Deposit Mix (% of Total Deposits):
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-2 field-sub-label">MIN</div>
            <div class="col-sm-2 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(depositMetric, index) in asDepositMix"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="depositMetric.id"
                  @input="depositMetricUpdate(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="depositMix.id"
                    v-for="(depositMix, index) in asDepositMixList"
                    :key="index"
                    >{{ depositMix.statement_name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asDepositMix.$each.$iter[index].min.$error
                  }"
                  v-model="depositMetric.min"
                  :disabled="!depositMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asDepositMix.$each.$iter[index].max.$error
                  }"
                  v-model="depositMetric.max"
                  :disabled="!depositMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asDepositMix.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{
                depositMetric.id &&
                  asStrategyValues.deposit_mix[depositMetric.id]
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-12 field-label">
              Fee Mix (% of Earning Assets):
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-2 field-sub-label">MIN</div>
            <div class="col-sm-2 field-sub-label">MAX</div>
          </div>
          <div
            class="row pb-2"
            v-for="(feeMetric, index) in asFeeMix"
            :key="index"
          >
            <div class="col-sm-4">
              <b-input-group class="mb-0">
                <b-form-select
                  v-model="feeMetric.id"
                  @input="feeMetricUpdate(index)"
                >
                  <template slot="first">
                    <option :value="null">Please Select</option>
                  </template>
                  <option
                    :value="asFeeMix.id"
                    v-for="(asFeeMix, index) in asFeeMixList"
                    :key="index"
                    >{{ asFeeMix.statement_name }}</option
                  >
                </b-form-select>
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asFeeMix.$each.$iter[index].min.$error
                  }"
                  v-model="feeMetric.min"
                  :disabled="!feeMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].min.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{
                    'is-invalid': $v.asFeeMix.$each.$iter[index].max.$error
                  }"
                  v-model="feeMetric.max"
                  :disabled="!feeMetric.id"
                >
                </b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].max.required"
                  >either min/max value required</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.asFeeMix.$each.$iter[index].max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-2 align-self-center">
              {{ feeMetric.id && asStrategyValues.fee_mix[feeMetric.id] }}
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card-body>
</template>

<script>
import { requiredIf, decimal } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import Multiselect from 'vue-multiselect'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const { mapMultiRowFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const isValidRange = (min, max) => {
  let minVal = parseFloat(min)
  let maxVal = parseFloat(max)
  if (isNaN(minVal) || isNaN(maxVal)) {
    return true
  }
  return minVal <= maxVal
}

export default {
  name: 'AdvancedSearchCPGStrategy',
  components: {
    Multiselect
  },
  props: {
    validateForm: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asStrategyValues: state => state.asStrategyValues,
      asLoanMixList: state => state.asLoanMixList,
      asDepositMixList: state => state.asDepositMixList,
      asFeeMixList: state => state.asFeeMixList,
      asLoanFocusList: state => state.asLoanFocusList,
      asFundingFocusList: state => state.asFundingFocusList,
      asOBSFocusList: state => state.asOBSFocusList,
      asLoanMix: state => state.asLoanMix
    }),
    ...mapFields(['asLoanFocus', 'asFundingFocus', 'asOBSFocus']),
    ...mapMultiRowFields(['asLoanMix', 'asDepositMix', 'asFeeMix'])
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setASStrategyFormValidity',
      'resetASLoanMetricData',
      'resetASDepositMetricData',
      'resetASFeeMetricData'
    ]),
    loanMetricUpdate(index) {
      if (!this.asLoanMix[index].id) {
        this.resetASLoanMetricData(index)
      }
    },
    depositMetricUpdate(index) {
      if (!this.asDepositMix[index].id) {
        this.resetASDepositMetricData(index)
      }
    },
    feeMetricUpdate(index) {
      if (!this.asFeeMix[index].id) {
        this.resetASFeeMetricData(index)
      }
    }
  },
  validations: {
    asLoanMix: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asLoanMix) {
            return asLoanMix.id && !asLoanMix.max
          }),
          isBefore(val, asLoanMix) {
            return isValidRange(val, asLoanMix.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asLoanMix) {
            return asLoanMix.id && !asLoanMix.min
          }),
          isAfter(val, asLoanMix) {
            return isValidRange(asLoanMix.min, val)
          }
        }
      }
    },
    asDepositMix: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asDepositMix) {
            return asDepositMix.id && !asDepositMix.max
          }),
          isBefore(val, asDepositMix) {
            return isValidRange(val, asDepositMix.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asDepositMix) {
            return asDepositMix.id && !asDepositMix.min
          }),
          isAfter(val, asDepositMix) {
            return isValidRange(asDepositMix.min, val)
          }
        }
      }
    },
    asFeeMix: {
      $each: {
        min: {
          decimal,
          required: requiredIf(function(asFeeMix) {
            return asFeeMix.id && !asFeeMix.max
          }),
          isBefore(val, asFeeMix) {
            return isValidRange(val, asFeeMix.max)
          }
        },
        max: {
          decimal,
          required: requiredIf(function(asFeeMix) {
            return asFeeMix.id && !asFeeMix.min
          }),
          isAfter(val, asFeeMix) {
            return isValidRange(asFeeMix.min, val)
          }
        }
      }
    }
  },
  watch: {
    validateForm(newVal) {
      if (newVal) {
        this.$v.$touch()
        this.setASStrategyFormValidity(!this.$v.$invalid)
        this.$emit('strategicValidity', !this.$v.$invalid)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
