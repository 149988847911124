<template>
  <div
    :class="[
      manageSuperAdminBoard ? 'cursor-pointer' : '',
      'header-logo text-right company-logo'
    ]"
    ref="headerLogo"
    @click="manageLogo"
    v-if="hasLogo"
  ></div>
  <div
    :class="[
      manageSuperAdminBoard ? 'cursor-pointer' : '',
      isVendor ? 'vendor-default' : 'fi-default',
      'header-logo text-right logo-default'
    ]"
    ref="headerLogo"
    @click="manageLogo"
    id="defaultLogo"
    v-else
  ></div>
</template>

<script>
// global
import { mapGetters } from 'vuex'
import router from '@/router'

export default {
  name: 'HeaderLogo',
  props: {
    logoUrl: {
      type: String,
      required: true
    },
    logoType: {
      type: String,
      default: 'FI'
    },
    logoId: {}
  },
  mounted() {
    let logo = new window.Image()
    let vm = this

    logo.crossOrigin = 'Anonymous'

    logo.onload = function() {
      if (vm.$refs.headerLogo) {
        vm.$refs.headerLogo.appendChild(logo)
        vm.hasLogo = true
      }
    }

    logo.onerror = function() {
      vm.hasLogo = false
    }

    logo.src = this.logoUrl
  },
  data() {
    return {
      hasLogo: false
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageSuperAdminBoard']),
    isVendor() {
      return this.logoType === 'vendor'
    }
  },
  methods: {
    manageLogo() {
      if (this.manageSuperAdminBoard) {
        router.push({
          path: '/super_admin/upload_logo',
          query: { [this.isVendor ? 'vendor_id' : 'fi_id']: this.logoId }
        })
      }
    }
  },
  watch: {
    logoUrl() {
      let logo = new window.Image()
      let vm = this

      logo.crossOrigin = 'Anonymous'

      logo.onload = function() {
        if (vm.$refs.headerLogo) {
          if (vm.$refs.headerLogo.firstElementChild) {
            vm.$refs.headerLogo.removeChild(
              vm.$refs.headerLogo.firstElementChild
            )
          }
          vm.$refs.headerLogo.appendChild(logo)
          vm.hasLogo = true
        }
      }

      logo.onerror = function() {
        if (vm.$refs.headerLogo && vm.$refs.headerLogo.firstElementChild) {
          vm.$refs.headerLogo.removeChild(vm.$refs.headerLogo.firstElementChild)
        }
        vm.hasLogo = false
      }

      logo.src = this.logoUrl
    }
  }
}
</script>

<style lang="scss">
.header-logo {
  width: 3rem;
  height: 2.1rem;
  margin-right: 0.5rem;

  img {
    max-width: 3rem;
    max-height: 2rem;
    border-radius: 5px;
    border: 0;
    object-position: center;
    object-fit: cover;
    vertical-align: -webkit-baseline-middle;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.logo-default {
    &:before {
      font-family: 'FontAwesome';
      font-size: 1.33em;
      top: 3px;
    }

    &.vendor-default {
      &:before {
        content: '\F0F7';
        font-size: 1.5rem;
      }
    }

    &.fi-default {
      &:before {
        content: '\F19C';
      }
    }
  }
}
</style>
