var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-body',{staticClass:"py-3 px-5"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_1"}},[_vm._v("Vendor Diversity:")])]),_c('b-popover',{attrs:{"target":"seg_1","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Vendor Diversity is a purchase-behavior segmentation scheme classifying financial institutions by the proportion of a basket of technology solutions that are purchased from their core provider. Technology solutions within that basket include online bill pay, debit card processing, credit card processing, retail online & mobile banking, business online banking, online account opening, website management & hosting, mortgage loan origination system, consumer loan origination system, investment platform and personal financial management. Institutions are segmented as (1) Core Centric (2) Low Diversity (3) Moderate Diversity or (4) Best-of-Breed based on non-core purchase percentage breakpoints of less than 33%, 33% to 66%, 67% to 80% and greater than 80% respectively. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asVendorDiversityList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asVendorDiversity),callback:function ($$v) {_vm.asVendorDiversity=$$v},expression:"asVendorDiversity"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.vendor_diversity)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_2"}},[_vm._v("Retail Services Innovation:")])]),_c('b-popover',{attrs:{"target":"seg_2","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Retail Services Innovation is a purchase-behavior segmentation scheme classifying financial intuitions by the relative depth of their retail offering versus asset size peers. Institutions are segmented as (1) Very High (2) High (3) Moderate (4) Low or (5) Very Low based percentage breakpoints. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asRetailServiceInnovationList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asRetailServiceInnovation),callback:function ($$v) {_vm.asRetailServiceInnovation=$$v},expression:"asRetailServiceInnovation"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.innovation_score_retail)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_3"}},[_vm._v("Business Services Innovation:")])]),_c('b-popover',{attrs:{"target":"seg_3","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Business Services Innovation is a purchase-behavior segmentation scheme classifying financial intuitions by the relative depth of their business offering versus asset size peers. Institutions are segmented as (1) Very High (2) High (3) Moderate (4) Low or (5) Very Low based percentage breakpoints. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asBusinessServiceInnovationList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asBusinessServiceInnovation),callback:function ($$v) {_vm.asBusinessServiceInnovation=$$v},expression:"asBusinessServiceInnovation"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.innovation_score_business)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_4"}},[_vm._v("Acquisition Risk:")])]),_c('b-popover',{attrs:{"target":"seg_4","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Acquisition Risk measures how attractive the institution would be to an acquirer based on what an active acquirer would like purchase. The score is an aggregate of market share, market growth, institution growth, and institution profitability. Generally, an acquirer would like to purchase a high market share franchise in a high growth market with good FI growth and profitability. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asMergerRiskList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asMergerRisk),callback:function ($$v) {_vm.asMergerRisk=$$v},expression:"asMergerRisk"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.merger_risk_score)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_5"}},[_vm._v("Failure Risk:")])]),_c('b-popover',{attrs:{"target":"seg_5","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Failure Risk is an aggregate enterprise risk score for the institution based on four (4) contributing component scores: Asset Quality (25%), Capital Adequacy (25%), Earnings Quality (25%) and Liquidity Risk (25%). Each of the four components is in turn driven by contributing component metrics. Institutions are segmented as (1) Very High (2) High (3) Moderate (4) Low or (5) Very Low. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asFailureRiskList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asFailureRisk),callback:function ($$v) {_vm.asFailureRisk=$$v},expression:"asFailureRisk"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.failure_risk)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_6"}},[_vm._v("Organic Account Growth:")])]),_c('b-popover',{attrs:{"target":"seg_6","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" This is a sample description. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asOrganicAccountGrowthList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asOrganicAccountGrowth),callback:function ($$v) {_vm.asOrganicAccountGrowth=$$v},expression:"asOrganicAccountGrowth"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.org_acct_growth_5_yr_score)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_7"}},[_vm._v("Reported Account Growth:")])]),_c('b-popover',{attrs:{"target":"seg_7","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Reported Account Growth is a measure of a financial institution’s growth in deposit accounts. The segmentation is based on a five-year annualized growth rate in the number of deposit accounts (excluding retirement accounts) less than or equal to $250,000. The growth rate uses the number of accounts “as-reported.” This means that the growth rate could be impacted by acquisitions over the 5-year period. The growth classification (Very High, High, Moderate, Low, Very Low) is based on the national quintiles of account growth. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asReportedAccountGrowthList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asReportedAccountGrowth),callback:function ($$v) {_vm.asReportedAccountGrowth=$$v},expression:"asReportedAccountGrowth"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.acq_acct_growth_5_yr_score)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('span',{attrs:{"id":"seg_8"}},[_vm._v(" Acquisitive Score:")])]),_c('b-popover',{attrs:{"target":"seg_8","triggers":"hover","placement":"right","boundary":"window"}},[_vm._v(" Acquisitive Score examines the frequency and recency of an institution's acquisitions to score the likelihood that additional acquisitions will be competed in the near future. ")]),_c('div',{staticClass:"col-sm-4 pl-0"},[_c('b-input-group',{staticClass:"mb-0"},[_c('Multiselect',{staticClass:"simple-select form-control-multi cpg-multi-select",attrs:{"options":_vm.asAcquisitiveScoreList,"multiple":true,"close-on-select":false,"showLabels":false,"placeholder":"","searchable":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm._f("pluralize")(values.length,'option'))+" selected ")]}}]),model:{value:(_vm.asAcquisitiveScore),callback:function ($$v) {_vm.asAcquisitiveScore=$$v},expression:"asAcquisitiveScore"}})],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_vm._v(" "+_vm._s(_vm.asSegmentationValues.acquisitive_score)+" ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }