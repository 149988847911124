import Vue from 'vue'
import router from '@/router'

export default {
  handleError: (vm, error) => {
    if (error.response.status === 401) {
      this.renderLogin(vm)
    } else if (error.response.status === 403) {
      this.restrictedPage()
    } else if (error.response.status === 422) {
      let errorMessage = error.response.data.message.match(
        /Validation failed: (.*)/
      )[1]
      Vue.toasted.show(errorMessage, { icon: 'chain-broken', type: 'error' })
    } else {
      this.somethingWrong()
    }
  },
  handleActionError: (vm, error, message) => {
    if (error.response.status === 401) {
      this.renderLogin(vm)
    } else if (error.response.status === 403) {
      Vue.toasted.show(message, { icon: 'chain-broken', type: 'error' })
    } else {
      this.somethingWrong()
    }
  },
  renderLogin(vm) {
    vm.$store.dispatch('resetStoreData')
    vm.$store.dispatch('setRedirectUrl', vm.$route.fullPath)
    router.push('/sign_in')
  },
  restrictedPage() {
    Vue.toasted.show('Page access restricted. Please contact admin.', {
      icon: 'chain-broken',
      type: 'error'
    })
    router.push('/dashboard')
  },
  somethingWrong() {
    Vue.toasted.show(
      'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
      {
        icon: 'chain-broken',
        type: 'error'
      }
    )
  }
}
