<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="row justify-content-center">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              MIN
            </div>
            <div class="col-sm-6 field-label">
              MAX
            </div>
          </div>
        </div>
        <div class="col-sm-1 p-0 field-label">
          Subject FI
        </div>
      </div>

      <div class="row justify-content-center pb-3">
        <div class="col-sm-1 px-0 align-self-start pt-2 input-label--hz">
          <b>Size:</b>
        </div>
        <div class="col-sm-3">
          <b-form-select
            v-model="qsSizeMetric.id"
            :class="{ 'is-invalid': $v.qsSizeMetric.id.$error }"
          >
            <template slot="first">
              <option :value="null">Please Select</option>
            </template>
            <option
              :value="size.id"
              v-for="(size, index) in qsSizeMetricsList"
              :key="index"
              >{{ size.name }}</option
            >
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.qsSizeMetric.id.required"
            >at least one criteria required</b-form-invalid-feedback
          >
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.qsSizeMetric.min.$error }"
                  v-model="qsSizeMetric.min"
                  :disabled="!qsSizeMetric.id"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold">{{
                    sizeSymbol
                  }}</span></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.min.required"
                  >either min or max value should be
                  present</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.qsSizeMetric.max.$error }"
                  v-model="qsSizeMetric.max"
                  :disabled="!qsSizeMetric.id"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold">{{
                    sizeSymbol
                  }}</span></b-input-group-addon
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.max.required"
                  >either min or max value should be
                  present</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.qsSizeMetric.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-sm-1 p-0 align-flex-start text-center pt-2">
          {{ subjectFISize(qsSizeMetric.id) }}
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6 field-label">
              MIN
            </div>
            <div class="col-sm-6 field-label">
              MAX
            </div>
          </div>
        </div>
        <div class="col-sm-1 p-0 field-label">
          Subject FI
        </div>
      </div>

      <div class="row justify-content-center pb-3">
        <div class="col-sm-1 px-0 align-self-start pt-2 input-label--hz">
          <b>Profitability:</b>
        </div>
        <div class="col-sm-3">
          <b-form-select
            v-model="qsProfitabilityMetric.id"
            :class="{ 'is-invalid': $v.qsProfitabilityMetric.id.$error }"
          >
            <template slot="first">
              <option :value="null">Please Select</option>
            </template>
            <option
              :value="profitability.id"
              v-for="(profitability, index) in qsProfitabilityMetricsList"
              :key="index"
              >{{ profitability.name }}</option
            >
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.qsProfitabilityMetric.id.required"
            >at least one criteria required</b-form-invalid-feedback
          >
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.qsProfitabilityMetric.min.$error }"
                  v-model="qsProfitabilityMetric.min"
                  :disabled="!qsProfitabilityMetric.id"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.min.required"
                  >either min or max value should be
                  present</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.min.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.min.isBefore"
                  >should be lesser than max val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-input-group class="mb-0">
                <b-form-input
                  type="text"
                  :class="{ 'is-invalid': $v.qsProfitabilityMetric.max.$error }"
                  v-model="qsProfitabilityMetric.max"
                  :disabled="!qsProfitabilityMetric.id"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-addon class="input-group-append"
                  ><span class="input-group-text font-weight-bold"
                    >%</span
                  ></b-input-group-addon
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.max.required"
                  >either min or max value should be
                  present</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.max.decimal"
                  >should be decimal value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.qsProfitabilityMetric.max.isAfter"
                  >should be greater than min val</b-form-invalid-feedback
                >
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-sm-1 p-0 align-flex-start text-center pt-2">
          {{ qsFIProfitabilityDetail[qsProfitabilityMetric.id] || '-' }}
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-1"></div>
        <div class="col-sm-3 field-label">
          State(s)
        </div>
        <div class="col-sm-4 field-label">
          Region(s)
        </div>
        <div class="col-sm-1 p-0 field-label">
          Subject FI
        </div>
      </div>

      <div class="row justify-content-center pb-3">
        <div class="col-sm-1 px-0 align-self-start pt-2 input-label--hz">
          <b>Geography:</b>
        </div>
        <div class="col-sm-3">
          <Multiselect
            v-model="qsStates"
            :options="qsStatesList"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="code"
            :showLabels="false"
            placeholder=""
            :class="[
              { 'is-invalid': $v.qsStates.$error },
              'simple-select form-control-multi'
            ]"
            :searchable="true"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('state') }} selected
            </template>
          </Multiselect>
          <b-form-invalid-feedback v-if="!$v.qsStates.required"
            >at least one criteria required</b-form-invalid-feedback
          >
        </div>
        <div class="col-sm-4">
          <Multiselect
            v-model="qsRegions"
            :options="qsRegionsList"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="code"
            :showLabels="false"
            placeholder=""
            class="simple-select form-control-multi"
            :class="{ 'is-invalid': $v.qsRegions.$error }"
            :searchable="true"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('region') }} selected
            </template>
          </Multiselect>
          <b-form-invalid-feedback v-if="!$v.qsRegions.required"
            >at least one criteria required</b-form-invalid-feedback
          >
        </div>
        <div class="col-sm-1 p-0 align-flex-start text-center pt-2">
          {{ qsFIGeoDetail.state }}, {{ qsFIGeoDetail.regions }}
        </div>
      </div>

      <div class="row text-center py-3">
        <div class="col-sm-12">
          <b-button
            size
            variant="secondary"
            class="fw-btn mx-3 rounded"
            @click="resetData"
            >Reset</b-button
          >
          <button
            type="button"
            class="btn fw-btn btn-primary rounded"
            :disabled="submittingForm"
            @click="fetchQueryResults()"
          >
            Run Query
          </button>
        </div>
      </div>

      <v-wait for="fetchingFIs">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="7"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="pg-search-results">
          <AdvancedSearchCPGSearchResults
            :institutions="qsInstitutions"
            :type="type"
            :tabIndex="tabIndex"
            :selectedFI="selectedFI"
            @closeModal="closeModal"
            @pgCreated="peerGroupCreated"
            @pgUpdated="peerGroupUpdated"
          >
          </AdvancedSearchCPGSearchResults>
        </div>
      </v-wait>
    </div>
    <b-modal
      id="peerModifiedQSAlert"
      @ok="forceCloseModal"
      title="Alert"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      Changes made to peer group are not saved. Do you still want to close?
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { decimal, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import Qs from 'qs'
import AdvancedSearchCPGSearchResults from './AdvancedSearchCPGSearchResults'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

const isValidRange = (min, max) => {
  let minVal = parseFloat(min)
  let maxVal = parseFloat(max)
  if (isNaN(minVal) || isNaN(maxVal)) {
    return true
  }
  return minVal <= maxVal
}

export default {
  name: 'QuickSearchCustomPeerGroup',
  components: {
    Multiselect,
    AdvancedSearchCPGSearchResults
  },
  props: {
    selectedFI: {
      required: true,
      type: Object
    },
    tabIndex: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      submittingForm: false
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      qsInstitutions: state => state.qsInstitutions,
      selectedInstitutions: state => state.selectedInstitutions,
      peerGroup: state => state.peerGroup,
      qsSizeMetricsList: state => state.qsSizeMetricsList,
      qsProfitabilityMetricsList: state => state.qsProfitabilityMetricsList,
      qsStatesList: state => state.qsStatesList,
      qsRegionsList: state => state.qsRegionsList,
      qsSizeMetric: state => state.qsSizeMetric,
      qsProfitabilityMetric: state => state.qsProfitabilityMetric,
      qsStates: state => state.qsStates,
      qsRegions: state => state.qsRegions,
      qsFISizeDetail: state => state.qsFISizeDetail,
      qsFIProfitabilityDetail: state => state.qsFIProfitabilityDetail,
      qsFIGeoDetail: state => state.qsFIGeoDetail
    }),
    ...mapGetters('CustomPeerGroup', [
      'checkPeerName',
      'checkPeerDescription',
      'checkPeerMembers'
    ]),
    ...mapFields([
      'qsSizeMetric',
      'qsProfitabilityMetric',
      'qsStates',
      'qsRegions'
    ]),
    selectedInstitutionIds() {
      return this.selectedInstitutions.map(si => si.id)
    },
    sizeSymbol() {
      let selectedSize = this.qsSizeMetricsList.find(
        sm => sm.id === this.qsSizeMetric.id
      )
      if (selectedSize && selectedSize.name === 'Total Assets (000)') {
        return '$'
      } else {
        return '#'
      }
    }
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setQSInstitutions',
      'setSelectedInstitutions',
      'setQSSelectAllResults',
      'resetQSSizeMetricData',
      'resetQSProfitabilityMetricData',
      'resetQuickSearchUserInputs'
    ]),
    canRunQuery() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    fetchQueryResults() {
      if (this.canRunQuery()) {
        this.submittingForm = true
        this.$wait.start('fetchingFIs')

        return this.$http
          .get('/api/financial_institutions/quick_search', {
            params: {
              fi_type: this.selectedFI.fi_type,
              size: this.qsSizeMetric,
              profitability: this.qsProfitabilityMetric,
              states: this.qsStates.map(state => state.code),
              regions: this.qsRegions.map(region => region.code)
            },
            paramsSerializer: function(params) {
              return Qs.stringify(params, { arrayFormat: 'brackets' })
            },
            handleErrors: true
          })
          .then(res => {
            this.setQSInstitutions(res.data.financial_institutions)
            this.setQSSelectAllResults(false)
          })
          .then(() => {
            this.submittingForm = false
            this.$wait.end('fetchingFIs')
          })
      }
    },
    peerDataModified() {
      return !(
        this.checkPeerName &&
        this.checkPeerDescription &&
        this.checkPeerMembers
      )
    },
    closeModal() {
      if (this.peerDataModified()) {
        this.$bvModal.show('peerModifiedQSAlert')
      } else {
        this.setSelectedInstitutions([])
        this.$emit('closeModal')
      }
    },
    forceCloseModal() {
      this.setSelectedInstitutions([])
      this.$emit('closeModal')
    },
    peerGroupCreated() {
      this.$emit('pgCreated')
    },
    peerGroupUpdated() {
      this.$emit('pgUpdated')
    },
    resetData() {
      this.resetQuickSearchUserInputs()
    },
    subjectFISize(sizeMetricId) {
      let subjectFIData = this.qsFISizeDetail[sizeMetricId]
      if (!subjectFIData) {
        return '-'
      }

      if (this.sizeSymbol === '#') {
        return Vue.filter('numberFormat')(subjectFIData)
      } else {
        return subjectFIData
      }
    }
  },
  watch: {
    'qsSizeMetric.id': {
      handler: function(newVal) {
        if (!newVal) {
          this.resetQSSizeMetricData()
        }
      }
    },
    'qsProfitabilityMetric.id': {
      handler: function(newVal) {
        if (!newVal) {
          this.resetQSProfitabilityMetricData()
        }
      }
    }
  },
  validations: {
    qsSizeMetric: {
      id: {
        required: requiredIf(function() {
          return !(
            this.qsProfitabilityMetric.id ||
            this.qsStates.length ||
            this.qsRegions.length
          )
        })
      },
      min: {
        decimal,
        required: requiredIf(function() {
          return this.qsSizeMetric.id && !this.qsSizeMetric.max
        }),
        isBefore(val) {
          return isValidRange(val, this.qsSizeMetric.max)
        }
      },
      max: {
        decimal,
        required: requiredIf(function() {
          return this.qsSizeMetric.id && !this.qsSizeMetric.min
        }),
        isAfter(val) {
          return isValidRange(this.qsSizeMetric.min, val)
        }
      }
    },
    qsProfitabilityMetric: {
      id: {
        required: requiredIf(function() {
          return !(
            this.qsSizeMetric.id ||
            this.qsStates.length ||
            this.qsRegions.length
          )
        })
      },
      min: {
        decimal,
        required: requiredIf(function() {
          return (
            this.qsProfitabilityMetric.id && !this.qsProfitabilityMetric.max
          )
        }),
        isBefore(val) {
          return isValidRange(val, this.qsProfitabilityMetric.max)
        }
      },
      max: {
        decimal,
        required: requiredIf(function() {
          return (
            this.qsProfitabilityMetric.id && !this.qsProfitabilityMetric.min
          )
        }),
        isAfter(val) {
          return isValidRange(this.qsProfitabilityMetric.min, val)
        }
      }
    },
    qsStates: {
      required: requiredIf(function() {
        return !(
          this.qsSizeMetric.id ||
          this.qsProfitabilityMetric.id ||
          this.qsRegions.length
        )
      })
    },
    qsRegions: {
      required: requiredIf(function() {
        return !(
          this.qsSizeMetric.id ||
          this.qsProfitabilityMetric.id ||
          this.qsStates.length
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.field-label {
  font-weight: bold;
  font-size: 12px;
  color: #808080;
}
</style>
