<template>
  <b-modal
    size="lg"
    id="peer-group-share"
    :title="modalTitle"
    @show="fetchSharedGroups"
    modal-class="peer-group-share"
    hide-footer
  >
    <v-wait for="fetchingSharedGroups">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div>
            Use the tool below to share peer group
            <b>{{ peerGroup.name }}</b> among other user groups.
          </div>
        </div>
      </div>
      <div class="row list-group-container pt-0">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
              <div class="row card-sub-header mt-0">
                <div class="col-sm-5 card-sub-header">
                  User Groups
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 align-self-center pl-3 pr-0 tip-text">
              <b> <i> Click to share Peer Group </i> </b>
            </div>
            <div class="col-sm-8">
              <div class="full-border">
                <div class="filter-result p-0">
                  <b-card class="text-center" body-class="p-0">
                    <b-row>
                      <b-col md="12">
                        <div>
                          <b-card class="text-left">
                            <b-list-group>
                              <div v-for="(group, index) in userGroups" :key="index">
                                <b-list-group-item
                                  @click="selectGroup(group)"
                                  :class="{
                                    selected:
                                      group.id === selectedGroup &&
                                      selectedGroup.id,
                                    'px-1': true
                                  }"
                                >
                                  {{ group.name }}
                                  <span
                                    class="float-right fa fa-check pt-1 add-icon"
                                  ></span>
                                </b-list-group-item>
                              </div>
                            </b-list-group>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row list-group-container pb-1 pt-0">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
              <div class="row card-sub-header mt-0">
                <div class="col-sm-5 card-sub-header">
                  Shared Groups
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 align-self-center pl-3 pr-0 tip-text">
              <b> <i> Click to remove from user group </i> </b>
            </div>
            <div class="col-sm-8">
              <div>
                <div class="selected-result rounded form-control p-0">
                  <b-card class="text-center" body-class="p-0">
                    <b-row>
                      <b-col md="12">
                        <div>
                          <b-card class="text-left">
                            <b-list-group>
                              <div
                                v-for="(group, index) in selectedGroups"
                                :key="index"
                              >
                                <b-list-group-item
                                  v-on:click.once="removeGroup(group)"
                                  :class="{
                                    selected:
                                      group.id === selectedGroup &&
                                      selectedGroup.id,
                                    'px-1': true
                                  }"
                                >
                                  {{ group.name }}
                                  <span
                                    class="float-right fa fa-trash pt-1 remove-icon"
                                  ></span>
                                </b-list-group-item>
                              </div>
                            </b-list-group>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row align-items-top">
        <div class="container">
          <b-row class="list-group-container">
            <b-col sm="2"> </b-col>
            <b-col sm="8" class="text-center">
              <b-button
                size
                variant="secondary"
                class="fw-btn mx-3"
                @click="closeModal"
                >Close</b-button
              >
              <b-button
                size=""
                variant="primary"
                class="fw-btn-ext mx-3"
                @click="sharePeerGroup"
                >Share Peer Group</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </v-wait>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PeerGroupShare',
  props: {
    peerGroup: {
      type: Object,
      required: true,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      selectedGroups: [],
      selectedGroup: null
    }
  },
  computed: {
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    modalTitle() {
      return 'Share Peer Group: ' + this.peerGroup.name
    },
    selectedGroupIDs() {
      return this.selectedGroups.map(g => g.id)
    }
  },
  methods: {
    fetchSharedGroups() {
      this.$wait.start('fetchingSharedGroups')
      this.$http
        .get(`/api/peer_groups/${this.peerGroup.id}/shared_groups`, {
          handleErrors: true
        })
        .then(res => {
          this.selectedGroups = res.data.shared_groups
        })
        .then(() => {
          this.$wait.end('fetchingSharedGroups')
        })
    },
    selectGroup(group) {
      this.selectedGroup = group
      if (!this.selectedGroupIDs.includes(group.id)) {
        this.selectedGroups.unshift(group)
      }
    },
    removeGroup(group) {
      let selectedGps = this.selectedGroups
      selectedGps.splice(selectedGps.indexOf(group), 1)
    },
    closeModal() {
      this.selectedGroups = []
      this.selectedGroup = null
      this.$bvModal.hide('peer-group-share')
    },
    sharePeerGroup() {
      this.$http
        .post(
          '/api/group_peer_groups',
          {
            peer_group_id: this.peerGroup.id,
            group_ids: this.selectedGroupIDs
          },
          {
            handleErrors: true
          }
        )
        .then(() => {
          this.$toasted.show('Peer Group shared successfully.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.closeModal()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.peer-group-share {
  .filter-result,
  .selected-result {
    min-height: 8rem;
    max-height: 8rem;
  }
  .tip-text {
    color: #808080;
    font-weight: 350;
  }
}
</style>
