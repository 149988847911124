<template>
  <div>
    <div role="tablist">
      <b-card no-body class="mb-1 std-border">
        <b-card-header
          header-tag="header"
          class="p-0"
          role="tab"
          v-b-toggle.accordian-1
        >
          <div class="row p-2 accord-header" v-b-toggle.accordion-1>
            <div class="col-sm-8">
              <h6 class="mb-0">
                <b>FI Profile:</b> Size | Market Area
                <sup
                  ><span
                    class="fa fa-lg fa-exclamation-circle error-icon pl-1"
                    v-if="!isASProfileFormValid"
                    title="Validation failed"
                  ></span
                ></sup>
              </h6>
            </div>
            <div class="col-sm-4 text-right">
              <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <AdvancedSearchCPGProfile
            :selectedFI="selectedFI"
            :validateForm="validateProfileForm"
            @profileValidity="updateProfileValidity"
          >
          </AdvancedSearchCPGProfile>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1 std-border">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <div class="row p-2 accord-header" v-b-toggle.accordion-2>
            <div class="col-sm-8">
              <h6 class="mb-0">
                <b>Strategic Focus:</b> Loan Mix | Deposit Mix | Fee Mix
                <sup
                  ><span
                    class="fa fa-lg fa-exclamation-circle error-icon pl-1"
                    v-if="!isASStrategyFormValid"
                    title="Validation failed"
                  ></span
                ></sup>
              </h6>
            </div>
            <div class="col-sm-4 text-right">
              <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <AdvancedSearchCPGStrategy
            :validateForm="validateStrategicForm"
            @strategicValidity="updateStrategicValidity"
          >
          </AdvancedSearchCPGStrategy>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1 std-border">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <div class="row p-2 accord-header" v-b-toggle.accordion-3>
            <div class="col-sm-8">
              <h6 class="mb-0">
                <b>Performance:</b> Profit | Growth | Risk
                <sup
                  ><span
                    class="fa fa-lg fa-exclamation-circle error-icon pl-1"
                    v-if="!isASPerformanceFormValid"
                    title="Validation failed"
                  ></span
                ></sup>
              </h6>
            </div>
            <div class="col-sm-4 text-right">
              <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <AdvancedSearchCPGPerformance
            :selectedFI="selectedFI"
            :validateForm="validatePerformanceForm"
            @performanceValidity="updatePerformanceValidity"
          ></AdvancedSearchCPGPerformance>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1 std-border">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <div class="row p-2 accord-header" v-b-toggle.accordion-4>
            <div class="col-sm-8">
              <h6 class="mb-0">
                <b>Offering:</b> Retail | Business | Vendors
                <sup
                  ><span
                    class="fa fa-lg fa-exclamation-circle error-icon pl-1"
                    v-if="!isASOfferingFormValid"
                    title="Validation failed"
                  ></span
                ></sup>
              </h6>
            </div>
            <div class="col-sm-4 text-right">
              <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <AdvancedSearchCPGOffering
            :validateForm="validateOfferingForm"
            @offeringValidity="updateOfferingValidity"
          ></AdvancedSearchCPGOffering>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1 std-border">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <div class="row p-2 accord-header" v-b-toggle.accordion-5>
            <div class="col-sm-8">
              <h6 class="mb-0">
                <b>Segmentation:</b> Innovation | Growth | Risk
              </h6>
            </div>
            <div class="col-sm-4 text-right">
              <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <AdvancedSearchCPGSegmentation></AdvancedSearchCPGSegmentation>
        </b-collapse>
      </b-card>
    </div>
    <div class="row text-center py-3">
      <div class="col-sm-12">
        <b-button
          size
          variant="secondary"
          class="fw-btn mx-3 rounded"
          @click="resetData"
          >Reset</b-button
        >
        <button
          type="button"
          class="btn fw-btn btn-primary rounded"
          @click="asyncFetchResultsAfterValidation()"
          :disabled="submittingForm"
        >
          Run Query
        </button>
      </div>
    </div>
    <v-wait for="fetchingFIs">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="7"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="pg-search-results">
        <AdvancedSearchCPGSearchResults
          :institutions="asInstitutions"
          :type="type"
          :tabIndex="tabIndex"
          :selectedFI="selectedFI"
          @closeModal="closeModal"
          @pgCreated="peerGroupCreated"
          @pgUpdated="peerGroupUpdated"
        ></AdvancedSearchCPGSearchResults>
      </div>
    </v-wait>
    <b-modal
      id="peerModifiedASAlert"
      @ok="forceCloseModal"
      title="Alert"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      Changes made to peer group are not saved. Do you still want to close?
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AdvancedSearchCPGProfile from './AdvancedSearchCPGProfile'
import AdvancedSearchCPGStrategy from './AdvancedSearchCPGStrategy'
import AdvancedSearchCPGPerformance from './AdvancedSearchCPGPerformance'
import AdvancedSearchCPGOffering from './AdvancedSearchCPGOffering'
import AdvancedSearchCPGSearchResults from './AdvancedSearchCPGSearchResults'
import AdvancedSearchCPGSegmentation from './AdvancedSearchCPGSegmentation'

export default {
  name: 'AdvancedSearchCustomPeerGroup',
  components: {
    AdvancedSearchCPGProfile,
    AdvancedSearchCPGStrategy,
    AdvancedSearchCPGPerformance,
    AdvancedSearchCPGOffering,
    AdvancedSearchCPGSegmentation,
    AdvancedSearchCPGSearchResults
  },
  props: {
    selectedFI: {
      required: true,
      type: Object
    },
    tabIndex: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      validateProfileForm: false,
      validateStrategicForm: false,
      validatePerformanceForm: false,
      validateOfferingForm: false,
      isProfileValid: false,
      isStrategicValid: false,
      isPerformanceValid: false,
      isOfferingValid: false,
      submittingForm: false
    }
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asInstitutions: state => state.asInstitutions,
      asTotalAsset: state => state.asTotalAsset,
      asDepositAccount: state => state.asDepositAccount,
      asBranches: state => state.asBranches,
      asCharters: state => state.asCharters,
      asSubCharter: state => state.asSubCharter,
      asMinorityOwned: state => state.asMinorityOwned,
      asGeographyType: state => state.asGeographyType,
      asStates: state => state.asStates,
      asRegions: state => state.asRegions,
      asMarketCoverage: state => state.asMarketCoverage,
      asFiSpecialtyTypes: state => state.asFiSpecialtyTypes,
      asMarketSize: state => state.asMarketSize,
      asMarketShare: state => state.asMarketShare,
      asMarketGrowth: state => state.asMarketGrowth,
      asMarketRank: state => state.asMarketRank,
      asAge: state => state.asAge,
      asAgeGroup: state => state.asAgeGroup,
      asTypeOfMembership: state => state.asTypeOfMembership,
      asLowIncomeDesignation: state => state.asLowIncomeDesignation,
      asLoanFocus: state => state.asLoanFocus,
      asFundingFocus: state => state.asFundingFocus,
      asOBSFocus: state => state.asOBSFocus,
      asLoanMix: state => state.asLoanMix,
      asDepositMix: state => state.asDepositMix,
      asFeeMix: state => state.asFeeMix,
      asProfit: state => state.asProfit,
      asGrowth: state => state.asGrowth,
      asRisk: state => state.asRisk,
      asProfitOpportunity: state => state.asProfitOpportunity,
      asProfitStrength: state => state.asProfitStrength,
      asGrowthOpportunity: state => state.asGrowthOpportunity,
      asGrowthStrength: state => state.asGrowthStrength,
      asRiskOpportunity: state => state.asRiskOpportunity,
      asRiskStrength: state => state.asRiskStrength,
      asOffering: state => state.asOffering,
      asVendorDiversity: state => state.asVendorDiversity,
      asRetailServiceInnovation: state => state.asRetailServiceInnovation,
      asBusinessServiceInnovation: state => state.asBusinessServiceInnovation,
      asMergerRisk: state => state.asMergerRisk,
      asFailureRisk: state => state.asFailureRisk,
      asOrganicAccountGrowth: state => state.asOrganicAccountGrowth,
      asReportedAccountGrowth: state => state.asReportedAccountGrowth,
      asAcquisitiveScore: state => state.asAcquisitiveScore,
      isASProfileFormValid: state => state.isASProfileFormValid,
      isASStrategyFormValid: state => state.isASStrategyFormValid,
      isASPerformanceFormValid: state => state.isASPerformanceFormValid,
      isASOfferingFormValid: state => state.isASOfferingFormValid,
      isASSegmentationFormValid: state => state.isASSegmentationFormValid
    }),
    ...mapGetters('CustomPeerGroup', [
      'checkPeerName',
      'checkPeerDescription',
      'checkPeerMembers'
    ]),
    isCreditUnion() {
      return this.selectedFI.fi_type === 'credit_union'
    },
    canQuery() {
      return (
        this.isProfileValid &&
        this.isStrategicValid &&
        this.isPerformanceValid &&
        this.isOfferingValid
      )
    }
  },
  methods: {
    ...mapMutations('CustomPeerGroup', [
      'setASInstitutions',
      'setASSelectAllResults',
      'setSelectedInstitutions',
      'resetAdvancedSearchUserInputs',
      'resetAdvancedSearchFormValidity'
    ]),
    asyncFetchResultsAfterValidation() {
      this.validateProfileForm = true
      this.validateStrategicForm = true
      this.validatePerformanceForm = true
      this.validateOfferingForm = true
    },
    fetchQueryResults() {
      if (this.canQuery) {
        this.submittingForm = true
        this.$wait.start('fetchingFIs')
        return this.$http
          .post(
            '/api/fi_advanced_search/search',
            {
              fi_advanced_search: {
                fi_type: this.selectedFI.fi_type,
                fi_profile: {
                  total_assets: this.asTotalAsset,
                  deposit_accounts: this.asDepositAccount,
                  branches: this.asBranches,
                  states: this.asStates.map(state => state.code),
                  regions: this.asRegions.map(region => region.code),
                  charters: this.asCharters.map(charter => charter.value),
                  subchapters: this.isCreditUnion ? null : this.asSubCharter,
                  minority_owned: this.isCreditUnion
                    ? null
                    : this.asMinorityOwned,
                  type_of_membership: this.isCreditUnion
                    ? this.asTypeOfMembership
                    : null,
                  low_income_designation: this.isCreditUnion
                    ? this.asLowIncomeDesignation
                    : null,
                  market_coverage: this.asMarketCoverage,
                  fi_specialty_types: this.asFiSpecialtyTypes,
                  market_size: this.asMarketSize,
                  market_share: this.asMarketShare,
                  market_share_rank: this.asMarketRank,
                  market_growth: this.asMarketGrowth,
                  fi_age: this.asAge,
                  fi_age_group: this.asAgeGroup
                },
                strategic_focus: {
                  loan_focus: this.asLoanFocus.map(loan => loan.value),
                  funding_focus: this.asFundingFocus.map(
                    funding => funding.value
                  ),
                  obs_focus: this.asOBSFocus.map(obs => obs.value),
                  loan_mix: this.asLoanMix,
                  deposit_mix: this.asDepositMix,
                  fee_mix: this.asFeeMix
                },
                performance: {
                  profit: this.asProfit,
                  growth: this.asGrowth,
                  risk: this.asRisk,
                  primary_profit_opportunity: this.asProfitOpportunity,
                  primary_profit_strength: this.asProfitStrength,
                  primary_growth_opportunity: this.asGrowthOpportunity,
                  primary_growth_strength: this.asGrowthStrength,
                  primary_risk_opportunity: this.asRiskOpportunity,
                  primary_risk_strength: this.asRiskStrength
                },
                offering: this.asOffering,
                segmentation: {
                  vendor_diversity: this.asVendorDiversity,
                  innovation_score_retail: this.asRetailServiceInnovation,
                  innovation_score_business: this.asBusinessServiceInnovation,
                  merger_risk_score: this.asMergerRisk,
                  failure_risk: this.asFailureRisk,
                  org_acct_growth_5_yr_score: this.asOrganicAccountGrowth,
                  acq_acct_growth_5_yr_score: this.asReportedAccountGrowth,
                  acquisitive_score: this.asAcquisitiveScore
                }
              }
            },
            {
              handleErrors: true
            }
          )
          .then(res => {
            this.setASInstitutions(res.data.result || [])
            this.setASSelectAllResults(false)
          })
          .then(() => {
            this.isProfileValid = false
            this.isStrategicValid = false
            this.isPerformanceValid = false
            this.isOfferingValid = false
            this.submittingForm = false
            this.$wait.end('fetchingFIs')
          })
      }
    },
    updateProfileValidity(isValid) {
      this.isProfileValid = isValid
      this.validateProfileForm = false
      this.fetchQueryResults()
    },
    updateStrategicValidity(isValid) {
      this.isStrategicValid = isValid
      this.validateStrategicForm = false
      this.fetchQueryResults()
    },
    updatePerformanceValidity(isValid) {
      this.isPerformanceValid = isValid
      this.validatePerformanceForm = false
      this.fetchQueryResults()
    },
    updateOfferingValidity(isValid) {
      this.isOfferingValid = isValid
      this.validateOfferingForm = false
      this.fetchQueryResults()
    },
    resetData() {
      this.resetAdvancedSearchFormValidity()
      this.resetAdvancedSearchUserInputs()
    },
    peerDataModified() {
      return !(
        this.checkPeerName &&
        this.checkPeerDescription &&
        this.checkPeerMembers
      )
    },
    closeModal() {
      if (this.peerDataModified()) {
        this.$bvModal.show('peerModifiedASAlert')
      } else {
        this.setSelectedInstitutions([])
        this.$emit('closeModal')
      }
    },
    forceCloseModal() {
      this.setSelectedInstitutions([])
      this.$emit('closeModal')
    },
    peerGroupCreated() {
      this.$emit('pgCreated')
    },
    peerGroupUpdated() {
      this.$emit('pgUpdated')
    }
  }
}
</script>

<style lang="scss" scoped>
.accord-header {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

/deep/ .field-label {
  font-weight: 500;
}

/deep/ .longer-label-value {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

/deep/ .field-label-value {
  font-weight: 500;
  color: #808080;
}

/deep/ .field-sub-label {
  font-weight: bold;
  font-size: 12px;
  color: #808080;
}

.error-icon {
  color: #f26c6c;
}
</style>
