<template>
  <b-card-body class="py-3 px-5">
    <b-form autocomplete="off">
      <div class="row">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-4">
              <span id="seg_1">Vendor Diversity:</span>
            </div>
            <b-popover
              target="seg_1"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Vendor Diversity is a purchase-behavior segmentation scheme
              classifying financial institutions by the proportion of a basket
              of technology solutions that are purchased from their core
              provider. Technology solutions within that basket include online
              bill pay, debit card processing, credit card processing, retail
              online & mobile banking, business online banking, online account
              opening, website management & hosting, mortgage loan origination
              system, consumer loan origination system, investment platform and
              personal financial management. Institutions are segmented as (1)
              Core Centric (2) Low Diversity (3) Moderate Diversity or (4)
              Best-of-Breed based on non-core purchase percentage breakpoints of
              less than 33%, 33% to 66%, 67% to 80% and greater than 80%
              respectively.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asVendorDiversity"
                  :options="asVendorDiversityList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.vendor_diversity }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_2">Retail Services Innovation:</span>
            </div>
            <b-popover
              target="seg_2"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Retail Services Innovation is a purchase-behavior segmentation
              scheme classifying financial intuitions by the relative depth of
              their retail offering versus asset size peers. Institutions are
              segmented as (1) Very High (2) High (3) Moderate (4) Low or (5)
              Very Low based percentage breakpoints.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asRetailServiceInnovation"
                  :options="asRetailServiceInnovationList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.innovation_score_retail }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_3">Business Services Innovation:</span>
            </div>
            <b-popover
              target="seg_3"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Business Services Innovation is a purchase-behavior segmentation
              scheme classifying financial intuitions by the relative depth of
              their business offering versus asset size peers. Institutions are
              segmented as (1) Very High (2) High (3) Moderate (4) Low or (5)
              Very Low based percentage breakpoints.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asBusinessServiceInnovation"
                  :options="asBusinessServiceInnovationList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.innovation_score_business }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_4">Acquisition Risk:</span>
            </div>
            <b-popover
              target="seg_4"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Acquisition Risk measures how attractive the institution would be
              to an acquirer based on what an active acquirer would like
              purchase. The score is an aggregate of market share, market
              growth, institution growth, and institution profitability.
              Generally, an acquirer would like to purchase a high market share
              franchise in a high growth market with good FI growth and
              profitability.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asMergerRisk"
                  :options="asMergerRiskList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.merger_risk_score }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_5">Failure Risk:</span>
            </div>
            <b-popover
              target="seg_5"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Failure Risk is an aggregate enterprise risk score for the
              institution based on four (4) contributing component scores: Asset
              Quality (25%), Capital Adequacy (25%), Earnings Quality (25%) and
              Liquidity Risk (25%). Each of the four components is in turn
              driven by contributing component metrics. Institutions are
              segmented as (1) Very High (2) High (3) Moderate (4) Low or (5)
              Very Low.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asFailureRisk"
                  :options="asFailureRiskList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.failure_risk }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_6">Organic Account Growth:</span>
            </div>
            <b-popover
              target="seg_6"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              This is a sample description.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asOrganicAccountGrowth"
                  :options="asOrganicAccountGrowthList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.org_acct_growth_5_yr_score }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <span id="seg_7">Reported Account Growth:</span>
            </div>
            <b-popover
              target="seg_7"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Reported Account Growth is a measure of a financial institution’s
              growth in deposit accounts. The segmentation is based on a
              five-year annualized growth rate in the number of deposit accounts
              (excluding retirement accounts) less than or equal to $250,000.
              The growth rate uses the number of accounts “as-reported.” This
              means that the growth rate could be impacted by acquisitions over
              the 5-year period. The growth classification (Very High, High,
              Moderate, Low, Very Low) is based on the national quintiles of
              account growth.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asReportedAccountGrowth"
                  :options="asReportedAccountGrowthList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.acq_acct_growth_5_yr_score }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <span id="seg_8"> Acquisitive Score:</span>
            </div>
            <b-popover
              target="seg_8"
              triggers="hover"
              placement="right"
              boundary="window"
            >
              Acquisitive Score examines the frequency and recency of an
              institution's acquisitions to score the likelihood that additional
              acquisitions will be competed in the near future.
            </b-popover>
            <div class="col-sm-4 pl-0">
              <b-input-group class="mb-0">
                <Multiselect
                  v-model="asAcquisitiveScore"
                  :options="asAcquisitiveScoreList"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  placeholder=""
                  class="simple-select form-control-multi cpg-multi-select"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values }">
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </Multiselect>
              </b-input-group>
            </div>
            <div class="col-sm-4 align-self-center">
              {{ asSegmentationValues.acquisitive_score }}
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card-body>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import Multiselect from 'vue-multiselect'

const { mapFields } = createHelpers({
  getterType: 'CustomPeerGroup/getField',
  mutationType: 'CustomPeerGroup/updateField'
})

export default {
  name: 'AdvancedSearchCPGSegmentation',
  components: {
    Multiselect
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('CustomPeerGroup', {
      asVendorDiversityList: state => state.asVendorDiversityList,
      asRetailServiceInnovationList: state =>
        state.asRetailServiceInnovationList,
      asBusinessServiceInnovationList: state =>
        state.asBusinessServiceInnovationList,
      asMergerRiskList: state => state.asMergerRiskList,
      asFailureRiskList: state => state.asFailureRiskList,
      asOrganicAccountGrowthList: state => state.asOrganicAccountGrowthList,
      asReportedAccountGrowthList: state => state.asReportedAccountGrowthList,
      asAcquisitiveScoreList: state => state.asAcquisitiveScoreList,
      asSegmentationValues: state => state.asSegmentationValues
    }),
    ...mapFields([
      'asVendorDiversity',
      'asRetailServiceInnovation',
      'asBusinessServiceInnovation',
      'asMergerRisk',
      'asFailureRisk',
      'asOrganicAccountGrowth',
      'asReportedAccountGrowth',
      'asAcquisitiveScore'
    ])
  },
  methods: {
    ...mapMutations('CustomPeerGroup', ['setASSegmentationFormValidity'])
  }
}
</script>

<style lang="scss" scoped></style>
